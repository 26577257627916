import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions, FlatList, TouchableOpacity, ActivityIndicator } from 'react-native';
import { BarChart, PieChart } from 'react-native-chart-kit';
import { EXPO_PUBLIC_OSPOS_ENDPOINT } from '../../../config/app.conf';
import Loader from '../../common/Loader';
import { toCurrency } from '../../../utils/app_utils';
import colors from '../../../strings/colors';
import OxygenGaugeChart from './OxygenGaugeChart';
import { useSelector } from 'react-redux';

const chartConfig = {
    backgroundGradientFrom: "#FFFFFF",
    backgroundGradientTo: "#FFFFFF",
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    barPercentage: 0.5,
    decimalPlaces: 2,
    formatYLabel: (y) => `N$${parseInt(y)}`,
    useShadowColorFromDataset: false,
    fromZero: true
};

const screenWidth = Dimensions.get('window').width;

const PotenciaDashboard = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const company = useSelector(state => state.company);

    const LOCATIONS = ['No1', 'No2', 'No3'];
    const targets = { No1: 450, No2: 500, No3: 600 };
    const pieChartColors = [colors.stay_blue, colors.light_gray, colors.light_blue, '#4BC0C0', '#9966FF'];

    const fetchData = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(EXPO_PUBLIC_OSPOS_ENDPOINT + '/report');
            if (!response.ok) throw new Error('Failed to fetch data from server');
            const jsonData = await response.json();
            setData(jsonData);
        } catch (err) {
            setError('Failed to load data: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const barData = {
        labels: LOCATIONS,
        datasets: [{
            data: data?.dailyRevenue ? data.dailyRevenue.map(rev => rev.daily_revenue) : [0, 0, 0]
        }]
    };

    const pieData = data?.dailyProfit ? data.dailyProfit.map((profit, index) => ({
        name: LOCATIONS[index],
        profit: profit.daily_profit,
        color: pieChartColors[index % pieChartColors.length],
        legendFontColor: "#7F7F7F",
        legendFontSize: 15
    })) : [];

    const totalRevenue = data?.dailyRevenue?.reduce((total, rev) => total + rev.daily_revenue, 0) || 0;
    const totalProfit = data?.dailyProfit?.reduce((total, profit) => total + profit.daily_profit, 0) || 0;

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.center}>{company.name}</Text>
            <TouchableOpacity
                onPress={fetchData}
                style={[styles.reloadButton, loading && styles.disabledButton]}
                disabled={loading}
            >
                <Text style={styles.reloadButtonText}>Reload Data</Text>
                {loading && <ActivityIndicator color="white" style={{ marginLeft: 10 }} />}
            </TouchableOpacity>

            <View style={styles.card}>
                <Text style={styles.cardTitle}>Daily Revenue</Text>
                <BarChart
                    data={barData}
                    width={screenWidth - 50}
                    height={220}
                    flatColor={true}
                    chartConfig={chartConfig}
                    verticalLabelRotation={30}
                    fromZero={true}
                    showBarTops={true}
                    showValuesOnTopOfBars={true}
                />
                <Text style={styles.center}>Total Revenue: {toCurrency(totalRevenue || 0)}</Text>
            </View>

            <View style={styles.card}>
                <Text style={styles.cardTitle}>Daily Profit</Text>
                <PieChart
                    data={pieData}
                    width={screenWidth}
                    height={220}
                    chartConfig={chartConfig}
                    accessor={"profit"}
                    backgroundColor={"transparent"}
                    paddingLeft={"15"}
                    yAxisLabel='N$'
                    center={[10, 10]}
                    absolute
                    avoidFalseZero={true}
                />
                <Text style={styles.center}>Total Profit: {toCurrency(totalProfit || 0)}</Text>
            </View>

            {data?.dailyProfit && data.dailyProfit.map((profit, index) => (
                <View key={index} style={styles.card}>
                    <Text style={styles.cardTitle}>{`Potencia ${LOCATIONS[index]}`}</Text>
                    <OxygenGaugeChart
                        value={profit.daily_profit || 0}
                        maxValue={targets[LOCATIONS[index]]}
                    />
                    <Text style={styles.center}>
                        Profit: {toCurrency(profit.daily_profit || 0)} / Target: {toCurrency(targets[LOCATIONS[index]])}
                    </Text>
                </View>
            ))}

            {data?.topSellingItems && Object.keys(data.topSellingItems).map((location, index) => (
                <View key={`top-${index}`} style={styles.card}>
                    <Text style={styles.cardTitle}>{`${LOCATIONS[index]} Fast Moving Items`}</Text>
                    <FlatList
                        data={data.topSellingItems[location]}
                        keyExtractor={(item, idx) => idx.toString()}
                        renderItem={({ item }) => (
                            <View style={styles.row}>
                                <Text style={styles.itemText}>{item.name}</Text>
                                <Text style={styles.itemQty}>{item.total_quantity}</Text>
                            </View>
                        )}
                    />
                </View>
            ))}

            {data?.mostProtitableItems && Object.keys(data.mostProtitableItems).map((location, index) => (
                <View key={`profitable-${index}`} style={styles.card}>
                    <Text style={styles.cardTitle}>{`${LOCATIONS[index]} Most Profitable Items`}</Text>
                    <FlatList
                        data={data.mostProtitableItems[location]}
                        keyExtractor={(item, idx) => idx.toString()}
                        renderItem={({ item }) => (
                            <View style={styles.row}>
                                <Text style={styles.itemText}>{item.name}</Text>
                                <Text style={styles.itemProfit}>{toCurrency(item.total_profit || 0)}</Text>
                            </View>
                        )}
                    />
                </View>
            ))}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 10,
    },
    card: {
        margin: 10,
        padding: 15,
        backgroundColor: 'white',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        textAlign: 'center',
        fontFamily: 'Poppins-Bold',
        textTransform: 'uppercase',
        
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
    },
    itemText: {
        fontSize: 16,
        flex: 3,
        fontFamily: 'Poppins',
    },
    itemQty: {
        color: colors.orange,
        fontSize: 16,
        flex: 1,
        textAlign: 'right',
        fontFamily: 'Poppins-Bold',
    },
    itemProfit: {
        color: colors.green,
        fontSize: 16,
        flex: 1,
        textAlign: 'right',
        fontFamily: 'Poppins-Bold',
    },
    center: {
        textAlign: 'center',
        marginVertical: 10,
        color: colors.gray,
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    reloadButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
        padding: 12,
        backgroundColor: colors.stay_blue,
        borderRadius: 8,
    },
    reloadButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    disabledButton: {
        opacity: 0.5,
    },
});

export default PotenciaDashboard;
