/**
 * DepositItem.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */
import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { toCurrency } from '../../utils/app_utils';
import ConfirmModal from '../../components/common/ConfirmModal';

import moment from 'moment';
import { PENDING } from '../../config/constants/approval-statues.const';

const DepositItem = ({ deposit, onEdit, onView, onDelete }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const timeAgo = moment(deposit.created_at).fromNow();

  const handleDeletePress = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = (confirm) => {
    if (confirm) {
      onDelete(deposit);
    }
    setShowDeleteConfirm(false);
  };

  return (
    <View style={[styles.container, {
      borderLeftColor: deposit.status === PENDING ? colors.red : colors.green,
      borderLeftWidth: 5,
    }]}>
      <View style={styles.info}>
        <Text style={styles.source}>Deposited From {deposit.cash_account?.name}</Text>
        <Text style={styles.amount}>{toCurrency(deposit.deposit_amount)}</Text>
        <Text style={styles.source}>Deposited to {deposit.bank_account?.name}</Text>
        <Text style={styles.created}>{timeAgo}</Text>
        <Text style={styles.created}>Deposited By {deposit.created_by?.username}</Text>
      </View>
      <TouchableOpacity style={styles.actionButton} onPress={() => onView(deposit)}>
        <MaterialIcons name="remove-red-eye" size={22} color={colors.stay_blue} />
      </TouchableOpacity>
      {deposit.status === PENDING && <>
        <TouchableOpacity style={styles.actionButton} onPress={() => onEdit(deposit)}>
          <MaterialIcons name="edit" size={22} color={colors.stay_blue} />
        </TouchableOpacity>
        <TouchableOpacity style={styles.deleteButton} onPress={handleDeletePress}>
          <MaterialIcons name="delete" size={22} color={colors.red} />
        </TouchableOpacity>
      </>}

      {/* Confirmation Modal */}
      <ConfirmModal
        isVisible={showDeleteConfirm}
        onConfirm={() => confirmDelete(true)}
        message="Are you sure you want to delete this deposit?"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  info: {
    flex: 1,
  },
  title: {
    
    fontSize: 16,
    fontFamily: 'Poppins',
    textTransform: 'uppercase',
  },
  amount: {
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Poppins-Bold',
    color: colors.green,
  },
  source: {
    fontSize: 11,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: colors.gray,
  },
  created: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color: colors.light_gray,
  },
  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    marginRight: 10,
    backgroundColor: '#eee',
  },
  deleteButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    backgroundColor: '#eee',
  },
});

export default DepositItem;
