/**
 * AccountItem.jsx
 *
 * @autor Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../../strings/colors';
import { toCurrency } from '../../utils/app_utils';
import moment from 'moment';

const AccountItem = ({ account }) => {
  const timeAgo = moment(account.created_at).fromNow();

  return (
    <View style={[styles.container, {
        borderLeftColor: account.blocked ? colors.red : colors.stay_blue,
        borderLeftWidth: 5,
    }]}>
      <View style={styles.info}>
        <Text style={styles.title}>{account.name}</Text>
        <Text style={styles.balance}>{toCurrency(account.balance)}</Text>
        <Text style={styles.created}>Created {timeAgo}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  info: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins-Bold',
    textTransform: 'uppercase',
  },
  balance: {
    fontSize: 24,
    fontFamily: 'Poppins-Bold',
    color: colors.green,
  },
  created: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color: colors.light_gray,
  },
});

export default AccountItem;
