/**
 * menu-items.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

/**
 * const menuItems = [
    { icon: 'person-outline', label: 'Profile' },
    { icon: 'settings-outline', label: 'Accounts' },
    { icon: 'lock-open-outline', label: 'Change Password' },
    { icon: 'business-outline', label: 'Swith Companies' },
    { icon: 'cash-outline', label: 'Cashups' },
    { icon: 'albums-outline', label: 'Bills' },
    { icon: 'briefcase-outline', label: 'Deposits' },
    { icon: 'log-out-outline', label: 'Logout' },
  ];
 */

    const PROFILE = {icon: 'person-outline', label: 'Profile'};
    const ACCOUNTS = {icon: 'settings-outline', label: 'Accounts'};
    const CHANGE_PASSWORD = {icon: 'lock-open-outline', label: 'Change Password'};
    const COMPANIES = {icon: 'business-outline', label: 'Swith Companies'};
    const CASHUPS = {icon: 'cash-outline', label: 'Cashups'};
    const BILLS = {icon: 'albums-outline', label: 'Bills'};
    const DEPOSITS = {icon: 'briefcase-outline', label: 'Deposits'};
    const LOGOUT = {icon: 'log-out-outline', label: 'Logout'};

   const ADMIN_MENU_ITEMS = [
        PROFILE,
        ACCOUNTS,
        CHANGE_PASSWORD,
        COMPANIES,
        CASHUPS,
        BILLS,
        DEPOSITS,
        LOGOUT
    ];

    const SUPERVISOR_MENU_ITEMS = [
        PROFILE,
        CHANGE_PASSWORD,
        COMPANIES,
        CASHUPS,
        BILLS,
        DEPOSITS,
        LOGOUT
    ];

    const CASHIER_MENU_ITEMS = [
        PROFILE,
        CHANGE_PASSWORD,
        CASHUPS,
        LOGOUT
    ];


export {
    ADMIN_MENU_ITEMS,
    SUPERVISOR_MENU_ITEMS,
    CASHIER_MENU_ITEMS
}

