import { AppState } from 'react-native';
import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./userSlice";
import { api } from "./api";
import { setItem, getItem } from './storage';
import { filterSlice } from './filterSlice';
import { listingSlice } from './listingSlice';
import { companySlice } from './companySlice';
import { cashupSlice } from './cashupSlices';
import { depositSlice } from './depositSlice';
import { billSlice } from './billSlice';
import { employeeSlice } from './employeeSlice';

const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        filter: filterSlice.reducer,
        listing: listingSlice.reducer,
        company: companySlice.reducer,
        cashup: cashupSlice.reducer,
        deposit: depositSlice.reducer,
        bill: billSlice.reducer,
        employee: employeeSlice.reducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

let appState = AppState.currentState;

const handleAppStateChange = async (nextAppState) => {
    if (appState.match(/inactive|background/) && nextAppState === "active") {
        // App has come to the foreground
    } else if (appState === "active" && nextAppState.match(/inactive|background/)) {
        // App is going to the background or becoming inactive
        const state = store.getState();
        await setItem('reduxState', JSON.stringify(state));
    }

    appState = nextAppState;
};

AppState.addEventListener('change', handleAppStateChange);

// Asynchronously load the state and update the store
const loadInitialState = async () => {
    const initialState = await getItem('reduxState');
    if (initialState) {
        store.dispatch({
            type: 'LOAD_INITIAL_STATE',
            payload: JSON.parse(initialState) 
        });
    }
    // Don't remove the saved state if you want it to be persistent
    // await removeItem('reduxState');
};

loadInitialState();

export default store;
