import React, { useState, useEffect } from 'react';
import { FlatList, View, Text, StyleSheet, Button } from 'react-native';
import Listing from './Listing';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { listingActions } from '../../redux/listingSlice';
import { useCreateListingInteractionMutation, useGetListingQuery } from '../../redux/api';
import Loader from '../common/Loader';
import { VIEW } from '../../config/enums/interaction-types.enum';
import { useSelector } from 'react-redux';

const Listings = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  // Pagination state
  const [page, setPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const user = useSelector(state => state.user);

  // Fetch listings with pagination
  const { data, isLoading, isError, refetch, isFetching } = useGetListingQuery({ skip: (page - 1) * pageSize, limit: pageSize });

  const [interact,interctionResult] = useCreateListingInteractionMutation();

  const onPropView = (listing) => {
    //set the listing in redux
    dispatch(listingActions.setListing(listing));
    //clear images
    dispatch(listingActions.clearImages());
    //create an interaction
    let interaction = {
      listing:listing._id,
      interaction_type:VIEW
    }
    if(user._id!==''){
      interaction = {created_by:user._id,...interaction};
    }
    interact(interaction);
    //navigate to view listing
    navigation.navigate('ViewListing'); 
  };

  const handleLoadMore = async () => {
    setPage(prevPage => prevPage + 1);
  };

  const renderListing = ({ item }) => <Listing data={item} onViewProp={() => onPropView(item)} />;

  useEffect(() => {
    refetch();
  }, [page, pageSize, refetch]);

  if (isLoading) {
    return <Loader message='Loading Listings...' />;
  }

  if (isError) {
    return <View style={styles.errorContainer}><Text>Error loading listings.</Text></View>;
  }

  return (
    <FlatList
      data={data}
      renderItem={renderListing}
      keyExtractor={item => item._id}
      refreshing={isLoading}
      onRefresh={refetch}
      onEndReached={handleLoadMore}
      onEndReachedThreshold={0.5}
      style={styles.container}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#eee',
    width: '100%',
  },
  errorContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  // Add more styles as needed
});

export default Listings;
