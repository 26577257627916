import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, Button } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { toCurrency } from '../../utils/app_utils';
import { APPROVED, PENDING } from '../../config/constants/cashup-stutuses';
import ConfirmModal from '../../components/common/ConfirmModal';
import { useSelector } from 'react-redux';

const CashupItem = ({ cashup, onEdit, onView, onDelete }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const user = useSelector(state => state.user);

  const handleDeletePress = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = (confirm) => {
    if (confirm) {
        onDelete(cashup); // Assuming onEdit can also handle delete by passing a flag
    }
    setShowDeleteConfirm(false);
  };

  // Determine the color for the over_short_amount
  const overShortColor = cashup.over_short_amount < -5 || cashup.over_short_amount > 5 ? colors.orange : colors.green;

  return (
    <View style={[styles.container,{
        borderLeftWidth:3, 
        borderLeftColor: cashup.cashup_status === APPROVED ? colors.light_green : colors.orange
        }]}>
      <View style={styles.info}>
        <Text style={styles.title}>{cashup.location?.name || 'No Location'}</Text>
        <Text style={styles.subtitle}>{new Date(cashup.created_at).toLocaleDateString()}</Text>
        <Text style={styles.cashier}>Cashier: {cashup.cashier?.first_name || 'Unknown'}</Text>
        <Text style={styles.price}> Cash: {toCurrency(cashup.total_amont)}</Text>
        <Text style={styles.price}> POS: {toCurrency(cashup.total_system_cash)}</Text>
        <Text style={[styles.overShort, {color: overShortColor}]}>
         {cashup.over_short_amount>0? 'Over':'Short'} {toCurrency(cashup.over_short_amount)}
        </Text>
        
      </View>
      <TouchableOpacity style={styles.actionButton} onPress={() => onView(cashup)}>
        <MaterialIcons name="remove-red-eye" size={22} color={colors.stay_blue} />
      </TouchableOpacity>
      {(cashup.cashup_status === PENDING && cashup.created_by._id===user._id) && <TouchableOpacity style={styles.actionButton} onPress={() => onEdit(cashup)}>
        <MaterialIcons name="edit" size={22} color={colors.stay_blue} />
      </TouchableOpacity>}
      {(cashup.cashup_status === PENDING && cashup.created_by._id===user._id) && <TouchableOpacity style={styles.deleteButton} onPress={handleDeletePress}>
        <MaterialIcons name="delete" size={22} color={colors.red} />
      </TouchableOpacity>}

      {/* Confirmation Modal */}

      <ConfirmModal
                isVisible={showDeleteConfirm}
                onConfirm={confirmDelete}
                message="Are you sure you want to delete this item?"
            />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  info: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins-Bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    color: 'grey',
    fontFamily: 'Poppins',
  },
  cashier: {
    fontSize: 14,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
  },
  overShort: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Poppins-Bold',
  },
  price: {
    fontSize: 16,
    fontWeight: 'bold',
    fontWeight: 'bold',
    fontFamily: 'Poppins-Bold',
  },
  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    marginRight: 10,
    backgroundColor: '#eee',
  },
  deleteButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    backgroundColor: '#eee',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 15,
    width: '100%',
  },
});

export default CashupItem;
