/**
 * Navigator.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2023
 * All rights reserved
 */
import React, { useState, useCallback, useMemo, memo } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Image, View } from 'react-native';
import colors from '../strings/colors';
import Login from '../screens/Auth/Login';
import Register from '../screens/Auth/Register';
import Profile from '../screens/Main/Profile';
import ChangePassword from '../screens/Auth/ChangePassword';
import MenuIcon from '../components/stay/MenuIcon';
import MenuPopup from '../components/stay/MenuPopup';
import Landing from '../screens/Main/Landing';
import ProfileIcon from '../components/common/ProfileIcon';
import ViewListing from '../screens/ViewListing/ViewListing';
import NewListing from '../screens/NewListing/NewListing';
import { EXPO_PUBLIC_API_ENDPOINT } from '../config/app.conf';
import { useSelector } from 'react-redux';
import MyListings from '../screens/MyListings/MyListings';
import { ACCOUNTS, BILLS, CASHUPS, CHANGE_PASSWORD, COMPANIES, DEPOSITS, HOME, LOGIN, MY_LISTINGS, NEW_BILL, NEW_CASHUP, NEW_DEPOSIT, NEW_LISTING, PROFILE, REGISTER, RESET_PASSWORD, VIEW_BILL, VIEW_CASHUP, VIEW_DEPOSIT, VIEW_LISTING } from '../config/constants/nav-routes.const';

import ResetPassword from '../screens/Auth/ResetPassword';
import Companies from '../screens/Main/Companies';
import ViewCashup from '../screens/Cashup/ViewCashup';
import Cashup from '../screens/Cashup/Cashup';
import Cashups from '../screens/Cashup/Cashups';
import Bills from '../screens/Bills/Bills';
import NewBill from '../screens/Bills/NewBill';
import ViewBill from '../screens/Bills/ViewBill';
import Deposits from '../screens/Deposits/Deposits';
import NewDeposit from '../screens/Deposits/NewDeposit';
import ViewDeposit from '../screens/Deposits/ViewDeposit';
import Accounts from '../screens/Accounts/Accounts';

const logo = require('../../assets/images/logo.png');

const Stack = createStackNavigator();



const logoIcon = logo;

console.log('ENDPOINT', EXPO_PUBLIC_API_ENDPOINT);

const LogoHeader = memo(() => (

    <Image
        style={{
            width: 200,
            height: 50,
            marginBottom: 0,
        }}
        source={logoIcon}
        resizeMode="contain"
    />
));

const RightHeader = memo(() => (
    <View style={{ flexDirection: 'row' }}>
        <ProfileIcon key={Math.random()} />
    </View>
));

const LeftHeader = memo(() => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [icon, setIcon] = useState('menu-outline');

    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [filterIcon, setFilterIcon] = useState('filter-outline');

    const user_state = useSelector(state => state.user);

    const toggleFilter = useCallback(() => {
        if (isFilterVisible) {
            setIsFilterVisible(false);
            setFilterIcon('filter-outline');
        } else {
            setIsFilterVisible(true);
            setFilterIcon('close');
        }
    }, [isFilterVisible]);

    const toggleMenu = useCallback(() => {
        if (isMenuVisible) {
            setIsMenuVisible(false);
            setIcon('menu-outline');
        } else {
            setIsMenuVisible(true);
            setIcon('close');
        }
    }, [isMenuVisible]);

    return (
        <View style={{ flexDirection: 'row' }}>
            <MenuIcon key={Math.random()} onPress={toggleMenu} icon={icon} />
            <MenuPopup key={Math.random()} visible={isMenuVisible} close={toggleMenu} />
        </View>
    );
});

function Navigator() {

    const listing = useSelector(state => state.listing);
    const cashup = useSelector(state => state.cashup);


    const commonHeaderOptions = useMemo(() => ({
        headerTitle: () => <LogoHeader />,
        headerRight: () => <RightHeader />,
        headerLeft: () => <LeftHeader />,
        headerTitleAlign: 'center',
        headerStyle: {
            backgroundColor: colors.white,
            shadowColor: '#fff',
        },
    }), []);



    return (
        <Stack.Navigator initialRouteName={HOME}>
            <Stack.Screen name={LOGIN} options={{  headerTitle: "" }} component={Login} />
            <Stack.Screen name={REGISTER} component={Register} options={{ headerTitle: "New Account" }} />
            <Stack.Screen name={RESET_PASSWORD} options={{ headerShown: false }} component={ResetPassword} />

           
            <Stack.Screen
                name={HOME}
                component={Landing}
                options={commonHeaderOptions}
            />
            <Stack.Screen
                name={VIEW_LISTING}
                component={ViewListing}
                options={{
                    headerTransparent: true,
                    headerTitle: '',
                    headerTintColor: '#fff', // If you have header buttons, set an appropriate color
                }}
            />

            <Stack.Screen
                name={NEW_LISTING}
                component={NewListing}
                options={{
                    headerTitle: listing._id!==''? 'Edit Listing' : 'New Listing',
                }}
            />
            <Stack.Screen name={MY_LISTINGS} component={MyListings} options={{ headerTitle: "My Listings" }}/>
            <Stack.Screen name={PROFILE} component={Profile} options={{ headerTitle: "Profile" }} />
            <Stack.Screen name={ACCOUNTS} component={Accounts} options={{ headerTitle: "Accounts" }} />
            <Stack.Screen name={COMPANIES} component={Companies} options={{ headerTitle: "My Companies" }} />
           
            {/**Bills */}
            <Stack.Screen name={BILLS} component={Bills} options={{ headerTitle: "Bills" }} />
            <Stack.Screen name={NEW_BILL} component={NewBill} options={{ headerTitle: "Bill" }} />
            <Stack.Screen name={VIEW_BILL} component={ViewBill} options={{ headerTitle: "View Bill" }} />
            

            {/**Deposits */}
            <Stack.Screen name={DEPOSITS} component={Deposits} options={{ headerTitle: "Deposits" }} />
            <Stack.Screen name={NEW_DEPOSIT} component={NewDeposit} options={{ headerTitle: 'Deposit'}} />
            <Stack.Screen name={VIEW_DEPOSIT} component={ViewDeposit} options={{ headerTitle: "View Deposit" }} />

            {/**Cashups */}
            <Stack.Screen name={CASHUPS} component={Cashups} options={{ headerTitle: "Cashups" }} />
            <Stack.Screen name={VIEW_CASHUP} component={ViewCashup} options={{ headerTitle: "View Cashup" }} />
            <Stack.Screen name={NEW_CASHUP} component={Cashup} options={{ headerTitle: cashup._id!==''? 'Edit Cashup':'New Cashup'}} />

            {/**Change Password */}
            <Stack.Screen name={CHANGE_PASSWORD} component={ChangePassword} options={{ headerTitle: "" }} />
            
        </Stack.Navigator>
    );
}

export default Navigator;
