/**
 * Companies.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, TouchableOpacity, StyleSheet, FlatList, Image } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Loader from '../../components/common/Loader';
import { useGetEmployeesQuery } from '../../redux/api';
import { companyActions } from '../../redux/companySlice';
import { employeeAction } from '../../redux/employeeSlice';
import colors from '../../strings/colors';
import StayIcon from '../../components/common/StayIcon';

const Companies = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const [instructions, setInstructions] = useState('SWITCH COMPANY');
    const userState = useSelector((state) => state.user);
    const employeesQuery = useGetEmployeesQuery(userState.email);

    useEffect(() => {
        employeesQuery.refetch();
    }, [employeesQuery.refetch]);

    useEffect(() => {
        const currentInstruction = "PICK OR CREATE COMPANY";
        if (employeesQuery.isSuccess) {
            setInstructions(currentInstruction);
        }
    }, [employeesQuery.isLoading]);

    const handleNewCompany = () => {
        navigation.navigate('IndustrySelection');
    };

    const handleEmployeeSelection = (employee) => {
        dispatch(companyActions.setCompany(employee.company));
        dispatch(employeeAction.setEmployee(employee));
        navigation.navigate('Home');
    };

    if (employeesQuery.isLoading) {
        return <Loader message='Loading Companies' />;
    }

    const renderEmployeeItem = ({ item: employee }) => (
        <TouchableOpacity style={styles.listItem} onPress={() => handleEmployeeSelection(employee)}>
           <Text style={styles.listItemText}>{employee?.company?.name || 'NO company'}</Text>
        </TouchableOpacity>
    );

    return (
        <ScrollView style={{ flex: 1, width: '100%' }}>
            <View style={{ alignItems: 'center', justifyContent: 'center', padding: 5, width: '100%' }}>
                <View style={styles.card}>
                    <StayIcon />
                    <View style={styles.cardHeader}>
                        <Text style={styles.cardTitle}>{instructions}</Text>
                    </View>
                    <FlatList
                        data={employeesQuery.data}
                        renderItem={renderEmployeeItem}
                        keyExtractor={(item) => item._id}
                    />
                </View>
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    card: {
        padding: 20,
        margin: 10,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 3,
    },
    cardHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    avatarIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    listItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    listItemText: {
        fontSize: 16,
        marginLeft: 10,
    },
    icon: {
        width: 24,
        height: 24,
    },
});

export default Companies;
