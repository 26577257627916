/**
 * NewBill.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import RoundedInput from '../../components/common/RoundedInput';
import colors from '../../strings/colors';
import { billActions } from '../../redux/billSlice';
import StayDropDown from '../../components/common/StayDropDown';
import { 
    useCreateCompanyExpenseMutation, 
    useGetAssetsAccountsQuery, 
    useGetExpenseAccountsQuery, 
    useUpdateCompanyExpenseMutation 
} from '../../redux/api';
import { BILLS, NEW_BILL } from '../../config/constants/nav-routes.const';
import BillEvidence from './BillEvidence';
import RoundedButton from '../../components/common/RoundedButton';
import Toast from 'react-native-toast-message';
import Loader from '../../components/common/Loader';
import { uploadEvidenceImage } from '../../utils/app_utils';
import { useNavigation } from '@react-navigation/native';

const screenWidth = Dimensions.get('window').width;

const NewBill = () => {

    const dispatch = useDispatch();

    const navigation = useNavigation();

    const bill = useSelector(state => state.bill);
    const {images} = useSelector(state => state.bill)
    const company = useSelector(state => state.company);
    const employee = useSelector(state => state.employee);
    const userState = useSelector(state => state.user);

    const [loadingMessage, setLoadingMessage] = useState('loading...');
    const [uploadingImages, setUploadingImages] = useState(false);
    
    const {data: categories, isLoading: categoriesLoading } = useGetExpenseAccountsQuery(company._id);
    const {data: assetAccounts, isLoading: assetAccountsLoading} = useGetAssetsAccountsQuery(company._id);

    const [createExpense, createExpenseResult] = useCreateCompanyExpenseMutation();
    const [updateExpense, updateExpenseResult] = useUpdateCompanyExpenseMutation();


    const handleSave = async () => {

        if(bill._id!==''){ 
            setLoadingMessage('Updating Listing...');
            updateExpense({
                ...bill,
                employee: userState._id,
            });

            return;
        }

        setLoadingMessage('Creating Cashup...');

        const filteredBill = Object.fromEntries(
            Object.entries(bill).filter(([_, value]) => value !== '')
        );
    
        try {
            const creatingBill = await createExpense({
                ...filteredBill,
                company: company._id,
                employee: userState._id,
            }).unwrap();
    
            setUploadingImages(true);

            setLoadingMessage('Uploading Images...');
    
            for (let i = 0; i < images.length; i++) {
                await uploadEvidenceImage(images[i], creatingBill._id, i)
            }
    
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Cashup and evidence uploaded successfully',
            });

            navigation.navigate(BILLS);
    
        } catch (error) {
            console.log(error);
            
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: error.message || 'An error occurred',
            });
        } finally {
            setUploadingImages(false);
        }
    };

    const handleChange = (field, value) => {
        dispatch(billActions.updateBill({ field, value }));
    };

    const handleCatagoryChange = (category) => {
        dispatch(billActions.setBillExpenseCategory(category._id));
        dispatch(billActions.setBillName(category.name));
        dispatch(billActions.setBillDescription(`Bill for ${category.name} by ${employee.first_name} on ${new Date().toLocaleDateString()}`));
    }

    useEffect(() => {
        if(updateExpenseResult.isSuccess){
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Bill updated successfully',
            });
            navigation.navigate(BILLS);
        }
    },[updateExpenseResult.isLoading]);

    if(createExpenseResult.isLoading || updateExpenseResult.isLoading || uploadingImages) return <Loader message={loadingMessage} />

    return (
        <ScrollView style={styles.container}>
            <View style={styles.layout}>

                   <BillEvidence />
               

                    <Text style={styles.inputLabel}>Bill Category</Text>
                    <StayDropDown
                        data={categories ? categories.map(cat => ({ value: cat.name, key: cat })) : []}
                        placeholder="Select Bill Category"
                        onSelectedItem={handleCatagoryChange}
                        defaultOption={bill.expense_category}
                    />

                    <Text style={styles.inputLabel}>Payment Source</Text>

                    <StayDropDown
                        data={assetAccounts ? assetAccounts.map(cat => ({ value: cat.name, key: cat._id })) : []}
                        placeholder="Select Payment Source"
                        onSelectedItem={(item) => dispatch(billActions.setBillSourceAccount(item))}
                        defaultOption={bill.expense_category}
                    />

                    <Text style={styles.inputLabel}>Amount</Text>
                    <RoundedInput
                        placeholder="Enter Amount"
                        value={bill.amount.toString()}
                        keyboardType="numeric"
                        onChangeText={(text) => dispatch(billActions.setBillAmount(text))}
                    />

                   <RoundedButton title="SAVE BILL" onPress={handleSave} style={styles.button} />

            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
    },
    layout: {
        flexDirection: 'column',
    },
    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2,
    },
    button: {
        marginTop: 20,
    }
});

export default NewBill;
