import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions, Switch } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import RoundedInput from '../../components/common/RoundedInput';

import colors from '../../strings/colors';
// Assume cashup actions are defined similarly to listingActions
import { useGetCompanyEmployeesQuery, useGetStockLocationsQuery } from '../../redux/api';
import { cashupActions } from '../../redux/cashupSlices';

import StayDropDown from '../../components/common/StayDropDown';
import { EXPO_PUBLIC_OSPOS_ENDPOINT } from '../../config/app.conf';


const screenWidth = Dimensions.get('window').width;

const CashupForm = () => {
    const dispatch = useDispatch();
    const cashup = useSelector(state => state.cashup);
    const company = useSelector(state => state.company);

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');

    const [data, setData] = useState(null);

    const { data: employees, isLoading: employeesLoading } = useGetCompanyEmployeesQuery(company._id);

    const { data: stockLocations, isLoading: stockLocationsLoading } = useGetStockLocationsQuery(company._id);

    const fetchData = async () => {
        setLoading(true);
        setError('');
        try {
            const response = await fetch(EXPO_PUBLIC_OSPOS_ENDPOINT + '/report');
            if (!response.ok) {
                throw new Error('Failed to fetch data from server');
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (err) {
            setError('Failed to load data: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleStockLocationChange = (location) => {
        if (!data || !data.dailyRevenue) {
            console.error('Daily revenue data is not available');
            return;
        }
        // Create a map dynamically based on available data and expected location codes
        const locationRevenueMap = data.dailyRevenue.reduce((acc, revenueData, index) => {
            const locationCode = `no${index + 1}`; // This assumes the location code needs to match this pattern
            acc[locationCode] = revenueData.daily_revenue;
            return acc;
        }, {});
    
        const locationDailySale = locationRevenueMap[location.location_code];
        
    
        // Dispatch updates to the Redux store
        dispatch(cashupActions.setCashupPeriodCashSale(locationDailySale));
        dispatch(cashupActions.setCashupLocation(location._id));
        dispatch(cashupActions.setCashupSaleAccount(location.sale_account));
        dispatch(cashupActions.setCashupCashAccount(location.cash_account));
    };
    
    



    return (
        <ScrollView style={styles.container}>
            <View style={styles.layout}>

                <Text style={styles.inputLabel}>Stock Location</Text>
                

                <StayDropDown
                    data={stockLocations ? stockLocations.map(account => ({ value: account.name, key: account })) : []}
                    placeholder="Select Stock Location"
                    onSelectedItem={handleStockLocationChange}
                    defaultOption={cashup.location}
                />

                <Text style={styles.inputLabel}>Cashier</Text>
                <StayDropDown
                    data={employees ? employees.map(employee => ({ value: employee.first_name, key: employee._id })) : []}
                    placeholder="Select Cashier"
                    onSelectedItem={(cashier) => dispatch(cashupActions.setCashupCashier(cashier))}
                    defaultOption={cashup.cashier}
                />

                <Text style={styles.inputLabel}>Cash Sale Total</Text>
                <RoundedInput
                    placeholder="Enter Cash Sale Total"
                    value={cashup.period_cash_sale?.toString() || ''}
                    keyboardType="numeric"
                    disabled = {true}
                    onChangeText={(text) => dispatch(cashupActions.setCashupPeriodCashSale(text))}
                />

                <Text style={styles.inputLabel}>Kazang Sale Total</Text>
                <RoundedInput
                    placeholder="Enter Kazang Sale Total"
                    value={cashup.kazang_sale?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupKazangSale(text))}
                />

                {/* <Text style={styles.inputLabel}>Card Sale Total</Text>
                <RoundedInput
                    placeholder="Enter Card Sale Total"
                    value={cashup.period_card_sale?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupPeriodCardSale(text))}
                /> */}

                

                <Text style={styles.inputLabel}>Cash In</Text>
                <RoundedInput
                    placeholder="Enter Cash In Amount"
                    value={cashup.cash_in?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCashIn(text))}
                />

                {cashup.cash_in !== 0 && (
                    <><Text style={styles.inputLabel}>Cash In Reason</Text>
                        <RoundedInput
                            placeholder="Enter Cash In Reason"
                            value={cashup.cash_in_reason || ''}
                            onChangeText={(text) => dispatch(cashupActions.setCashupCashInReason(text))}
                        />
                    </>
                )}

                <Text style={styles.inputLabel}>Cash Out</Text>
                <RoundedInput
                    placeholder="Enter Cash Out Amount"
                    value={cashup.cash_out?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCashOut(text))}
                />

                {cashup.cash_out !== 0 && (
                    <>
                        <Text style={styles.inputLabel}>Cash Out Reason</Text>
                        <RoundedInput
                            placeholder="Enter Cash Out Reason"
                            value={cashup.cash_out_reason || ''}
                            onChangeText={(text) => dispatch(cashupActions.setCashupCashOutReason(text))}
                        />
                    </>
                )}

                <Text style={styles.inputLabel}>Notes 200</Text>
                <RoundedInput
                    placeholder="Enter Notes 200"
                    value={cashup.notes_200?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupNotes200(text))}
                />

                <Text style={styles.inputLabel}>Notes 100</Text>
                <RoundedInput
                    placeholder="Enter Notes 100"
                    value={cashup.notes_100?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupNotes100(text))}
                />

                <Text style={styles.inputLabel}>Notes 50</Text>
                <RoundedInput
                    placeholder="Enter Notes 50"
                    value={cashup.notes_50?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupNotes50(text))}
                />

                <Text style={styles.inputLabel}>Notes 30</Text>
                <RoundedInput
                    placeholder="Enter Notes 30"
                    value={cashup.notes_30?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupNotes30(text))}
                />

                <Text style={styles.inputLabel}>Notes 20</Text>
                <RoundedInput
                    placeholder="Enter Notes 20"
                    value={cashup.notes_20?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupNotes20(text))}
                />

                <Text style={styles.inputLabel}>Notes 10</Text>
                <RoundedInput
                    placeholder="Enter Notes 10"
                    value={cashup.notes_10?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupNotes10(text))}

                />

                <Text style={styles.inputLabel}>Coins 5</Text>
                <RoundedInput
                    placeholder="Enter Coins 5"
                    value={cashup.coins_5?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCoins5(text))}
                />

                <Text style={styles.inputLabel}>Coins 1</Text>
                <RoundedInput
                    placeholder="Enter Coins 1"
                    value={cashup.coins_1?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCoins1(text))}
                />

                <Text style={styles.inputLabel}>Coins 50c</Text>

                <RoundedInput
                    placeholder="Enter Coins 50c"
                    value={cashup.coins_50c?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCoins50c(text))}
                />

                <Text style={styles.inputLabel}>Coins 10c</Text>
                <RoundedInput
                    placeholder="Enter Coins 10c"
                    value={cashup.coins_10c?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCoins10c(text))}
                />

                <Text style={styles.inputLabel}>Coins 5c</Text>
                <RoundedInput
                    placeholder="Enter Coins 5c"
                    value={cashup.coins_5c?.toString() || ''}
                    keyboardType="numeric"
                    onChangeText={(text) => dispatch(cashupActions.setCashupCoins5c(text))}
                />

            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
    },
    layout: {
        flexDirection: 'column',
    },
    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2,
    },
    button: {
        marginTop: 20,
    }
});

export default CashupForm;
