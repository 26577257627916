/**
 * NewDeposit.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */
import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import RoundedInput from '../../components/common/RoundedInput';

import colors from '../../strings/colors';
import { depositActions } from '../../redux/depositSlice';
import StayDropDown from '../../components/common/StayDropDown';
import { 
    useCreateCompanyDepositMutation, 
    useGetAssetsAccountsQuery, 
    useUpdateCompanyDepositMutation 
} from '../../redux/api';
import { DEPOSITS } from '../../config/constants/nav-routes.const';
import RoundedButton from '../../components/common/RoundedButton';
import Loader from '../../components/common/Loader';
import Toast from 'react-native-toast-message';
import { useNavigation } from '@react-navigation/native';
import DepositEvidence from './DepositEvidence';
import { uploadEvidenceImage } from '../../utils/app_utils';

const screenWidth = Dimensions.get('window').width;

const NewDeposit = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();

    const deposit = useSelector(state => state.deposit);
    const {images} = useSelector(state => state.deposit);
    const company = useSelector(state => state.company);
    const employee = useSelector(state => state.employee);
    const user = useSelector(state => state.user);

    const [loadingMessage, setLoadingMessage] = useState('Loading...');
    const [uploadingImages, setUploadingImages] = useState(false);
    
    const { data: bankAccounts, isLoading: bankAccountsLoading } = useGetAssetsAccountsQuery(company._id);
    const [createDeposit, createDepositResult] = useCreateCompanyDepositMutation();
    const [updateDeposit, updateDepositResult] = useUpdateCompanyDepositMutation();

    const handleSave = async () => {
        if (deposit._id !== '') {
            setLoadingMessage('Updating Deposit...');
            updateDeposit({ ...deposit, company: company._id, created_by: user._id });
            return;
        }

        setLoadingMessage('Creating Deposit...');

        const filteredDeposit = Object.fromEntries(
            Object.entries(deposit).filter(([_, value]) => value !== '')
        );

        try {
           const createdDeposit =  createDeposit({ 
                ...filteredDeposit, 
                company: company._id, 
                created_by: user._id 
            }).unwrap();

            setUploadingImages(true);

            setLoadingMessage('Uploading Images...');
    
            for (let i = 0; i < images.length; i++) {
                await uploadEvidenceImage(images[i], createdDeposit._id, i)
            }
    
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Cashup and evidence uploaded successfully',
            });
            navigation.navigate(DEPOSITS);
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: error.message || 'An error occurred',
            });
        } finally {
            setUploadingImages(false);
        }
    };

   

    useEffect(() => {
        if (updateDepositResult.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Deposit updated successfully',
            });
            navigation.navigate(DEPOSITS);
        }
    }, [updateDepositResult.isLoading]);

    if (createDepositResult.isLoading || updateDepositResult.isLoading) return <Loader message={loadingMessage} />;

    return (
        <ScrollView style={styles.container}>
            <View style={styles.layout}>
                <DepositEvidence/>
                <Text style={styles.inputLabel}>Bank Account</Text>
                <StayDropDown
                    data={bankAccounts ? bankAccounts.map(account => ({ value: account.name, key: account._id })) : []}
                    placeholder="Select Bank Account"
                    onSelectedItem={(item) => dispatch(depositActions.setDepositBankAccount(item))}
                    defaultOption={deposit.bank_account}
                />

                <Text style={styles.inputLabel}>Cash Account</Text>
                <StayDropDown
                    data={bankAccounts ? bankAccounts.map(account => ({ value: account.name, key: account._id })) : []}
                    placeholder="Select Cash Account"
                    onSelectedItem={(item) => dispatch(depositActions.setDepositCashAccount(item))}
                    defaultOption={deposit.bank_account?.name}
                />

                <Text style={styles.inputLabel}>Deposit Amount</Text>

                <RoundedInput
                    placeholder="Enter Deposit Amount"
                    value={deposit.deposit_amount.toString()}
                    keyboardType="numeric"
                    onChangeText={(text) =>dispatch(depositActions.setDepositAmount(text))}
                />

                <RoundedButton title="SAVE DEPOSIT" onPress={handleSave} style={styles.button} />
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
    },
    layout: {
        flexDirection: 'column',
    },
    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2,
    },
    button: {
        marginTop: 20,
    }
});

export default NewDeposit;
