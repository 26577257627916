/**
 * date-range-filters.enum.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

//["Today", "Yesterday", "This Month", "This Year", "All Time"];

const TODAY = 'Today';
const YESTERDAY = 'Yesterday';
const THIS_MONTH = 'This Month';
const THIS_YEAR = 'This Year';
const ALL_TIME = 'All Time';

const DATE_RANGE_FILTERS = [TODAY, YESTERDAY, THIS_MONTH, THIS_YEAR, ALL_TIME];

export {
    DATE_RANGE_FILTERS,
    TODAY,
    YESTERDAY,
    THIS_MONTH,
    THIS_YEAR,
    ALL_TIME
}