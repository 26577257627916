import React, { useEffect, useCallback } from 'react';
import { View, FlatList, StyleSheet, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { useGetAssetsAccountsQuery } from '../../redux/api';
import Loader from '../../components/common/Loader';
import AccountItem from './AccountItem';
import { toCurrency } from '../../utils/app_utils';

const Accounts = () => {
    const company = useSelector(state => state.company);

    const { data: accounts, isLoading, refetch, isFetching } = useGetAssetsAccountsQuery(company._id);

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    useEffect(() => {
        refetch();
    }, [refetch]);

    if (isLoading || isFetching) {
        return <Loader message="Loading Accounts..." />;
    }

    const totalBalance = accounts ? accounts.reduce((total, account) => total + account.balance, 0) : 0;

    return (
        <View style={styles.container}>
            <Text style={styles.totalBalance}>Total: {toCurrency(totalBalance)}</Text>

            <FlatList
                data={accounts || []}
                refreshing={isFetching}
                onRefresh={refetch}
                keyExtractor={item => item._id.toString()}
                renderItem={({ item }) => (
                    <AccountItem
                        account={item}
                    />
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    totalBalance: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 10,
    },
});

export default Accounts;
