/**
 * cashup-stutuses.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const PENDING = 'pending';
const APPROVED = 'approved';

const CASHUP_STATUSES = [PENDING, APPROVED];

export {
    PENDING,
    APPROVED,
    CASHUP_STATUSES
}