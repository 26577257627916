import React, { useState, useEffect, useCallback } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useGetCompanyExpensesQuery, useGetExpenseCategoriesQuery, useGetExpenseAccountsQuery, useUpdateCompanyExpenseMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import BillItem from './BillItem';
import StayDropDown from '../../components/common/StayDropDown';
import RoundedButton from '../../components/common/RoundedButton';
import { NEW_BILL, VIEW_BILL } from '../../config/constants/nav-routes.const';
import { billActions } from '../../redux/billSlice';
import { ADMIN, SUPERVISOR } from '../../config/constants/retail-role-codes.const';
import { DATE_RANGE_FILTERS, YESTERDAY, TODAY } from '../../config/enums/date-range-filters.enum';
import { getDateRange } from '../../utils/app_utils';
import Toast from 'react-native-toast-message';

const Bills = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const company = useSelector(state => state.company);
    const employee = useSelector(state => state.employee);
    const employeeRoleCode = employee.role.code;

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedDateRange, setSelectedDateRange] = useState(TODAY);

    const { data: bills, isLoading, refetch, isFetching } = useGetCompanyExpensesQuery({
        companyId: company._id,
        categoryId: selectedCategory,
        ...getDateRange(selectedDateRange),
    });

    const { data: categories, isLoading: categoriesLoading } = useGetExpenseAccountsQuery(company._id);
    const [updateBill, deleteBillResults] = useUpdateCompanyExpenseMutation();

    const CREATE_AND_FILTER = [ADMIN, SUPERVISOR];

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    useEffect(() => {
        refetch();
    }, [selectedCategory, selectedDateRange, refetch]);

    const dateRanges = DATE_RANGE_FILTERS;

    const handleCreateNew = () => {
        dispatch(billActions.resetBill());
        navigation.navigate(NEW_BILL);
    };

    const handleView = (bill) => {
        dispatch(billActions.setBill(bill));
        navigation.navigate(VIEW_BILL);
    };

    const handleEdit = (bill) => {
        dispatch(billActions.setBill(bill));
        navigation.navigate(NEW_BILL);
    };

    const handleDelete = (bill) => {
        updateBill({
            _id: bill._id,
            deleted: true 
        });
    };

    const handleClearFilters = () => {
        setSelectedCategory(null);
        setSelectedDateRange(TODAY);
    };

    useEffect(() => {
        if (deleteBillResults.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Bill deleted successfully',
            });
            refetch();
        }
    }, [deleteBillResults.isLoading]);

    if (isLoading || isFetching || categoriesLoading || deleteBillResults.isLoading) {
        return <Loader message="Loading Bills..." />;
    }

    return (
        <View style={styles.container}>
            {CREATE_AND_FILTER.includes(employeeRoleCode) && (
                <RoundedButton title="Create New Bill" onPress={handleCreateNew} />
            )}

            <StayDropDown
                data={categories ? categories.map(cat => ({ value: cat.name, key: cat._id })) : []}
                placeholder="Filter By Category"
                onSelectedItem={setSelectedCategory}
                defaultOption={selectedCategory}
            />

            <StayDropDown
                data={dateRanges.map(range => ({ value: range, key: range }))}
                placeholder="Filter By Date Range"
                onSelectedItem={setSelectedDateRange}
                defaultOption={selectedDateRange}
            />

            <RoundedButton backgroundColor="lightgray" title="Clear Filters" onPress={handleClearFilters} />

            <FlatList
                data={bills || []}
                refreshing={isFetching}
                onRefresh={refetch}
                keyExtractor={item => item._id.toString()}
                renderItem={({ item }) => (
                    <BillItem
                        bill={item}
                        onView={() => handleView(item)}
                        onEdit={() => handleEdit(item)}
                        onDelete={() => handleDelete(item)}
                    />
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
});

export default Bills;
