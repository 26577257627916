/**
 * TownSelector.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { MultipleSelectList } from 'react-native-dropdown-select-list';
import { TOWNS } from '../../config/enums/towns.enum';
import {useDispatch} from 'react-redux';
import { filterActions } from '../../redux/filterSlice';


const TownSelector = () => {
    const [selectedTowns, setSelectedTowns] = useState([]);

    const dispatch = useDispatch();

    const townData = TOWNS.map((town, index) => ({ key: index.toString(), value: town }));

    return (
      
            <MultipleSelectList
                setSelected={setSelectedTowns}
                data={townData}
                placeholder='Filter By Towns '
                save="value"
                label="Towns"
                onSelect={()=>dispatch(filterActions.setLocations(selectedTowns))}
                labelStyles={styles.labelStyle}
                boxStyles={
                    {
                        borderWidth: 1,
                        borderColor: '#eee',
                        backgroundColor: '#eee',
                        width: 300,
                        borderRadius: 20,
                        paddingVertical: 8,
                        paddingHorizontal: 10,
                    }
                }
            // You can add more styles and props as needed
            />
        
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        // Add more styles for the container as needed
    },
    label: {
        fontSize: 16,
        marginBottom: 10,
        // Add more styles for the label as needed
    },
    labelStyle: {
        // Style for the dropdown label
    },
    // Add more styles as needed
});

export default TownSelector;
