/**
 * ImageGallery.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

// ImageGallery.jsx
import React from 'react';
import { View, StyleSheet } from 'react-native';
import ImagePreview from './ImagePreview';

const ImageGallery = ({ images, onRemove }) => (
    <View style={styles.container}>
        {images.map(uri => <ImagePreview key={uri} uri={uri} onRemove={onRemove} />)}
    </View>
);

const styles = StyleSheet.create({
    container: { flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }
});

export default ImageGallery;
