/**
 * nav-routes.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const HOME =  'Home';
const NEW_LISTING = 'NewListing';
const VIEW_LISTING = 'ViewListing';
const MY_LISTINGS = 'MyListings';
const PROFILE   = 'Profile';
const LOGIN = 'Login';
const REGISTER = 'Register';
const CHANGE_PASSWORD = 'ChangePassword';
const RESET_PASSWORD = 'ResetPassword';
const ACCOUNTS = 'Accounts';
const BILLS = 'Bills';
const CASHUPS = 'Cashups';
const DEPOSITS = 'Deposits';
const VIEW_BILL = 'ViewBill';
const VIEW_CASHUP = 'ViewCashup';
const NEW_CASHUP = 'NewCashup';
const NEW_DEPOSIT = 'NewDeposit';
const NEW_BILL = 'NewBill';
const VIEW_DEPOSIT = 'ViewDeposit';
const COMPANIES = 'Companies';

export {
    HOME,
    NEW_LISTING,
    VIEW_LISTING,
    MY_LISTINGS,
    PROFILE,
    LOGIN,
    REGISTER,
    CHANGE_PASSWORD,
    RESET_PASSWORD,
    ACCOUNTS,
    BILLS,
    CASHUPS,
    DEPOSITS,
    VIEW_BILL,
    VIEW_CASHUP,
    VIEW_DEPOSIT,
    COMPANIES,
    NEW_CASHUP,
    NEW_DEPOSIT,
    NEW_BILL
}