/**
 * app_utils.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { EXPO_PUBLIC_API_ENDPOINT } from "../config/app.conf";
import * as ImageManipulator from 'expo-image-manipulator';
import { LISTING_DETAIL, LISTING_GALLERY, LISTING_THUMBNAIL } from "../config/constants/image-resize-width.const";
import { DETAIL, GALLERY, THUMBNAIL } from "../config/constants/image-prefixes.const";
import { ALL_TIME, TODAY, YESTERDAY, THIS_MONTH, THIS_YEAR} from "../config/enums/date-range-filters.enum";


const toCurrency = (number) => {
    // Convert number to a string and remove any commas
    const numString = String(number).replace(/,/g, '');

    // Check if the number is valid
    if (isNaN(numString)) {
        return 'Invalid number';
    }

    // Parse the number as a float
    const parsedNumber = parseFloat(numString);

    let abbreviatedNumber;
    if (parsedNumber >= 1000000) {
        abbreviatedNumber = `${(parsedNumber / 1000000).toFixed(1)}m`;
    } else if (parsedNumber >= 10000) {
        abbreviatedNumber = `${(parsedNumber / 1000).toFixed(1)}k`;
    } else {
        abbreviatedNumber = parsedNumber.toFixed(2);
    }

    // Create the currency string
    const currencyString = `N$${abbreviatedNumber}`;

    return currencyString;
}


const formatSize = (size, unit) => {
    // Check if the size is valid
    if (isNaN(size)) {
        return 'Invalid size';
    }

    // Format the size with the unit
    const formattedSize = `${size} ${unit}`;

    return formattedSize;
}

const getProfilePicUri = (user_id) => {
    return `${EXPO_PUBLIC_API_ENDPOINT}/stream/profile.jpg-${user_id}`;
}

const uploadImage = async (formData,id) => {
    try {
        const response = await fetch(`${EXPO_PUBLIC_API_ENDPOINT}/upload/${id}`, {
            method: 'POST',
            body: formData,
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Image upload failed');
        }

        return response.json();

    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;

    }
}

const resizeImage = async (uri, width) => {
    const resizedImage = await ImageManipulator.manipulateAsync(
        uri,
        [{ resize: { width } }],
        { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
    );

    return resizedImage;
}

const getFormData =  async (uri,_id,resizeWith,imagePrefix) => {

    const resizedImage = await resizeImage(uri, resizeWith);

    const uriParts = resizedImage.uri.split('.');
    const fileType = uriParts[uriParts.length - 1];

    const formData = new FormData();

    formData.append('file', {
        uri: resizedImage.uri,
        name: `${imagePrefix}.${fileType}`,
        type: `image/${fileType}`,
    });

    formData.append('_id', _id);

    return formData;
};

const uploadListingImage = async (uri,_id,index) => {
    //upload thumbnail
    const thumbanilFormData = await getFormData(uri, _id,LISTING_THUMBNAIL,`${THUMBNAIL}${index}`);
    await uploadImage(thumbanilFormData, _id);
    //upload galaxy image
    const galaxyFormData = await getFormData(uri, _id,LISTING_GALLERY,`${GALLERY}${index}`);
    await uploadImage(galaxyFormData, _id);
}

const uploadEvidenceImage = async (uri,_id,index) => {
    //upload thumbnail
    const thumbanilFormData = await getFormData(uri, _id,LISTING_DETAIL,`${DETAIL}${index}`);
    await uploadImage(thumbanilFormData, _id);
}

function formatCellphoneNumber(number, countryCode = '+264') {
    // Remove any non-digit characters except the leading plus sign
    let cleanedNumber = number.replace(/(?!^\+)\D/g, '');
  
    // Check if the number already starts with the country code
    if (cleanedNumber.startsWith(countryCode)) {
      return cleanedNumber;
    }
  
    // Check if the number starts with '0', remove it if so
    if (cleanedNumber.startsWith('0')) {
      cleanedNumber = cleanedNumber.substring(1);
    }
  
    return countryCode + cleanedNumber;
  }

  const getCashupTotalCash = (cashup) => {
    const {
        notes_200,
        notes_100,
        notes_50,
        notes_30,
        notes_20,
        notes_10,
        coins_5,
        coins_1,
        coins_50c,
        coins_10c,
        coins_5c
    } = cashup;

    return  parseInt(notes_200) * 200 +  parseInt(notes_100) * 100 +  parseInt(notes_50) * 50 +  parseInt(notes_30) * 30 +  parseInt(notes_20) * 20 +  parseInt(notes_10) * 10 +  parseInt(coins_5) * 5 +  parseInt(coins_1) +  parseInt(coins_50c) * 0.5 +  parseInt(coins_10c) * 0.1 +  parseInt(coins_5c) * 0.05;
}

const getSystemCashTotal = (cashup) => {

    const period_cash_sale = parseFloat(cashup?.period_cash_sale) || 0;
    const kazang_sale = parseFloat(cashup?.kazang_sale) || 0;
    const cash_in = parseFloat(cashup?.cash_in) || 0;
    const cash_out = parseFloat(cashup?.cash_out) || 0;


    const total_system_cash = (period_cash_sale + kazang_sale + cash_in) - cash_out; 
    

    return total_system_cash;

}

const getCashupDifference = (cashup) => {
    const total_cash_in_drawer = getCashupTotalCash(cashup);
    const total_system_cash = getSystemCashTotal(cashup);
    return total_cash_in_drawer - total_system_cash;
}

/**
 * Converts a user-friendly date range selection into start and end dates for querying.
 * 
 * @param {string} dateRange - The date range to convert.
 * @returns An object containing `startDate` and `endDate`.
 */
const getDateRange = (dateRange) => {
    const now = new Date();
    let startDate;
    let endDate = new Date(now.setHours(23, 59, 59, 999)); // Set to end of current day

    switch (dateRange) {
        case TODAY:
            startDate = new Date(now.setHours(0, 0, 0, 0)); // Set to start of current day
            break;
        case YESTERDAY:
            const yesterday = new Date(now.setDate(now.getDate() - 1));
            startDate = new Date(yesterday.setHours(0, 0, 0, 0));
            endDate = new Date(yesterday.setHours(23, 59, 59, 999));
            break;
        case THIS_MONTH:
            startDate = new Date(now.getFullYear(), now.getMonth(), 1); // Set to first day of the current month
            endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999); // Set to last day of the current month
            break;
        case THIS_YEAR:
            startDate = new Date(now.getFullYear(), 0, 1); // Set to January 1st of the current year
            endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59, 999); // Set to December 31st of the current year
            break;
        case ALL_TIME:
            startDate = null; // No start date for 'All Time'
            endDate = null; // No end date for 'All Time'
            break;
        default:
            throw new Error(`Unsupported date range: ${dateRange}`);
    }

    return {
        startDate: startDate ? startDate.toISOString() : null,
        endDate: endDate ? endDate.toISOString() : null
    };
};

 
  

export {
    toCurrency,
    formatSize,
    getProfilePicUri,
    uploadImage,
    resizeImage,
    getFormData,
    uploadListingImage,
    formatCellphoneNumber,
    uploadEvidenceImage,
    getCashupTotalCash,
    getSystemCashTotal,
    getCashupDifference,
    getDateRange

}