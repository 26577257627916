import React, { useState, useRef, useEffect } from 'react';
import { ScrollView, StyleSheet, View, Alert } from 'react-native';
import { useCameraPermissions } from 'expo-camera';
import Toast from 'react-native-toast-message';
import * as ImagePicker from 'expo-image-picker';
import { useSelector, useDispatch } from 'react-redux';
import RoundedButton from '../../components/common/RoundedButton';
import Loader from '../../components/common/Loader';
import CameraPermissionPrompt from '../../components/common/CameraPermissionPrompt';
import CameraInterface from '../../components/common/CameraInterface';
import ImageGallery from '../../components/common/ImageGallery';
import { billActions } from '../../redux/billSlice';
import { useDeleteListingImageMutation, useGetListingImagesQuery } from '../../redux/api';
import { uploadEvidenceImage } from '../../utils/app_utils';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';

const BillEvidence = () => {
    const cameraRef = useRef(null);
    const [isCameraActive, setIsCameraActive] = useState(false);
    const [facing, setFacing] = useState('back');
    const [permission, requestPermission] = useCameraPermissions();
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Loading Images...');
    const dispatch = useDispatch();
    const { images, _id } = useSelector((state) => state.bill);

    const { data: apiImages, isLoading: apiLoading, isSuccess } = useGetListingImagesQuery(_id);
    const [deleteImage, deleteImageResult] = useDeleteListingImageMutation();

    const toggleCameraFacing = () => setFacing((current) => (current === 'back' ? 'front' : 'back'));

    const takePhoto = async () => {
        if (cameraRef.current) {
            try {
                const photo = await cameraRef.current.takePictureAsync();
                const uri = photo.uri;

                dispatch(billActions.setImage(uri));
                if (_id !== '') {
                    setLoadingMessage('Uploading Image...');
                    setIsLoading(true);
                    await uploadEvidenceImage(uri, _id, images.length + 1);
                    setIsLoading(false);
                    Toast.show({
                        type: 'success',
                        text1: 'Success',
                        text2: 'Image uploaded successfully.',
                    });
                }
            } catch (error) {
                console.error('Error capturing photo:', error);
                Alert.alert('Error', 'An error occurred while capturing the photo.');
            }
        }
    };

    const pickImage = async () => {
        const galleryPermission = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (galleryPermission.status !== 'granted') {
            Alert.alert('No access to gallery', 'Gallery permissions are required.');
            return;
        }

        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
        });

        if (!result.canceled) {
            const uri = result.assets[0].uri;
            dispatch(billActions.setImage(uri));
            if (_id !== '') {
                setIsLoading(true);
                setLoadingMessage('Uploading Image...');
                await uploadEvidenceImage(uri, _id, images.length + 1);
                setIsLoading(false);
                Toast.show({
                    type: 'success',
                    text1: 'Success',
                    text2: 'Image uploaded successfully.',
                });
            }
        }
    };

    const removeImage = async (uri) => {
        if (uri.includes(EXPO_PUBLIC_API_ENDPOINT) && _id !== '') {
            setLoadingMessage('Deleting Image...');
            deleteImage(uri.split('/').pop());
        }
        dispatch(billActions.removeImage(uri));
    };

    useEffect(() => {
        if (isSuccess && _id !== '') {
            dispatch(billActions.clearImages());
            apiImages.forEach((image) => {
                dispatch(billActions.setImage(`${EXPO_PUBLIC_API_ENDPOINT}/stream/${image.filename}`));
            });
        }
    }, [isSuccess, _id, apiImages]);

    useEffect(() => {
        if (deleteImageResult.isError) {
            Alert.alert('Error', 'Failed to delete image.');
        }
    }, [deleteImageResult.isLoading]);

    if (!permission) {
        return <View />;
    }

    if (!permission.granted) {
        return <CameraPermissionPrompt requestPermission={requestPermission} />;
    }

    if (apiLoading || deleteImageResult.isLoading || isLoading) {
        return <Loader message={loadingMessage} />;
    }

    return (
        <ScrollView style={styles.container}>
            {isCameraActive ? (
                <CameraInterface
                    cameraRef={cameraRef}
                    facing={facing}
                    toggleFacing={toggleCameraFacing}
                    capturePhoto={takePhoto}
                    closeCamera={() => setIsCameraActive(false)}
                />
            ) : (
                <>
                    <RoundedButton title="Activate Camera" onPress={() => setIsCameraActive(true)} />
                    <RoundedButton title="Pick an image from gallery" onPress={pickImage} />
                    <ImageGallery images={images} onRemove={removeImage} />
                </>
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: { flex: 1, padding: 5, backgroundColor: '#eee' },
});

export default BillEvidence;
