/**
 * cashupSlices.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';
import { PENDING } from '../config/constants/approval-statues.const';

export const cashupSlice = createSlice({
    name: 'cashup',
    initialState: {
        _id: '',
        cashier: '',
        created_by: '',
        sale_account: '',
        cash_account: '',
        company: '',
        period_cash_sale: 0,
        period_card_sale: 0,
        kazang_sale: 0,
        location: '',
        cash_out: 0,
        cash_in: 0,
        cash_out_reason: '',
        cash_in_reason: '',
        cash_up_openning_time: '',
        cash_up_closing_time: '',
        cashup_status: PENDING,
        approved_by: '',
        //this primaliry based on Namibian currencies
        notes_200: 0,
        notes_100:  0,
        notes_50: 0,
        notes_30: 0,
        notes_20: 0,
        notes_10: 0,
        coins_5: 0,
        coins_1: 0,
        coins_50c: 0,
        coins_10c: 0,
        coins_5c: 0,
        created_at: '',
        images: [],

        over_short_amount: 0,
        total_amont: 0,
        total_system_cash: 0
    },

    reducers: {
        setCashup: (state, action) => {
            state._id = action.payload._id;
            state.cashier = action.payload.cashier;
            state.created_by = action.payload.created_by;
            state.sale_account = action.payload.sale_account;
            state.cash_account = action.payload.cash_account;
            state.company = action.payload.company;
            state.period_cash_sale = action.payload.period_cash_sale;
            state.period_card_sale = action.payload.period_card_sale;
            state.kazang_sale = action.payload.kazang_sale;
            state.location = action.payload.location;
            state.cash_out = action.payload.cash_out;
            state.cash_in = action.payload.cash_in;
            state.cash_out_reason = action.payload.cash_out_reason;
            state.cash_in_reason = action.payload.cash_in_reason;
            state.cash_up_openning_time = action.payload.cash_up_openning_time;
            state.cash_up_closing_time = action.payload.cash_up_closing_time;
            state.cashup_status = action.payload.cashup_status;
            state.approved_by = action.payload.approved_by;
            state.notes_200 = action.payload.notes_200;
            state.notes_100 = action.payload.notes_100;
            state.notes_50 = action.payload.notes_50;
            state.notes_30 = action.payload.notes_30;
            state.notes_20 = action.payload.notes_20;
            state.notes_10 = action.payload.notes_10;
            state.coins_5 = action.payload.coins_5;
            state.coins_1 = action.payload.coins_1;
            state.coins_50c = action.payload.coins_50c;
            state.coins_10c = action.payload.coins_10c;
            state.coins_5c = action.payload.coins_5c;
            state.created_at = action.payload.created_at;
            state.total_amont = action.payload.total_amont;
            state.total_system_cash = action.payload.total_system_cash;
            state.over_short_amount = action.payload.over_short_amount;
        },

        setCashupCashier: (state, action) => {
            state.cashier = action.payload
        },

        setCashupCreatedBy: (state, action) => {
            state.created_by = action.payload
        },

        setCashupSaleAccount: (state, action) => {
            state.sale_account = action.payload
        },

        setCashupCashAccount: (state, action) => {
            state.cash_account = action.payload
        },

        setCashupCompany: (state, action) => {
            state.company = action.payload
        },

        setCashupPeriodCashSale: (state, action) => {
            state.period_cash_sale = action.payload
        },

        setCashupPeriodCardSale: (state, action) => {
            state.period_card_sale = action.payload
        },

        setCashupKazangSale: (state, action) => {
            state.kazang_sale = action.payload
        },

        setCashupLocation: (state, action) => {
            state.location = action.payload;
        },

        setCashupCashOut: (state, action) => {
            state.cash_out = action.payload;
        },

        setCashupCashIn: (state, action) => {
            state.cash_in = action.payload;
        },

        setCashupCashOutReason: (state, action) => {
            state.cash_out_reason = action.payload;
        },

        setCashupCashInReason: (state, action) => {
            state.cash_in_reason = action.payload;
        },

        setCashupCashUpOpenningTime: (state, action) => {
            state.cash_up_openning_time = action.payload;
        },

        setCashupCashUpClosingTime: (state, action) => {
            state.cash_up_closing_time = action.payload;
        },

        setCashupStatus: (state, action) => {
            state.cashup_status = action.payload;
        },

        setCashupNotes200: (state, action) => {
            state.notes_200 = action.payload;
        },

        setCashupNotes100: (state, action) => {
            state.notes_100 = action.payload;
        },

        setCashupNotes50: (state, action) => {
            state.notes_50 = action.payload;
        },

        setCashupNotes30: (state, action) => {
            state.notes_30 = action.payload;
        },

        setCashupNotes20: (state, action) => {
            state.notes_20 = action.payload;
        },

        setCashupNotes10: (state, action) => {
            state.notes_10 = action.payload;
        },

        setCashupCoins5: (state, action) => {
            state.coins_5 = action.payload;
        },

        setCashupCoins1: (state, action) => {
            state.coins_1 = action.payload;
        },

        setCashupCoins50c: (state, action) => {
            state.coins_50c = action.payload;
        },

        setCashupCoins10c: (state, action) => {
            state.coins_10c = action.payload;
        },

        setCashupCoins5c: (state, action) => {
            state.coins_5c = action.payload;
        },

        setImage : (state, action) => {
            state.images.push(action.payload);
        },

        removeImage: (state, action) => {
            state.images = state.images.filter((image) => image !== action.payload);
        },

        clearImages: (state) => {
            state.images = [];
        },

        clearCashup: (state) => {
            state._id = '';
            state.cashier = '';
            state.created_by = '';
            state.sale_account = '';
            state.cash_account = '';
            state.company = '';
            state.period_cash_sale = 0;
            state.period_card_sale = 0;
            state.kazang_sale = 0;
            state.location = '';
            state.cash_out = 0;
            state.cash_in = 0;
            state.cash_out_reason = '';
            state.cash_in_reason = '';
            state.cash_up_openning_time = '';
            state.cash_up_closing_time = '';
            state.cashup_status = '';
            state.approved_by = '';
            state.notes_200 = 0;
            state.notes_100 = 0;
            state.notes_50 = 0;
            state.notes_30 = 0;
            state.notes_20 = 0;
            state.notes_10 = 0;
            state.coins_5 = 0;
            state.coins_1 = 0;
            state.coins_50c = 0;
            state.coins_10c = 0;
            state.coins_5c = 0;
            state.created_at = '';
            state.images = [];
            state.over_short_amount = 0;
            state.total_amont = 0;
            state.total_system_cash = 0;
        },
    }
        
});

export const cashupActions = cashupSlice.actions;