/**
 * billSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const billSlice = createSlice({
    name: 'bill',
    initialState: {
        _id: '',
        name: '',
        description: '',
        company: '',
        amount: 0,
        expense_category: '',
        source_account: '',
        payment_method: '',
        tax_amount: 0,
        tax_rate: '',
        employee: '',
        supplier: '',
        status: '',
        created_at: '',
        images: []
    },

    reducers: {
        setBill: (state, action) => {
            state._id = action.payload._id;
            state.name = action.payload.name;
            state.description = action.payload.description;
            state.company = action.payload.company;
            state.amount = action.payload.amount;
            state.expense_category = action.payload.expense_category;
            state.source_account = action.payload.source_account;
            state.payment_method = action.payload.payment_method;
            state.tax_amount = action.payload.tax_amount;
            state.tax_rate = action.payload.tax_rate;
            state.employee = action.payload.employee;
            state.supplier = action.payload.supplier;
            state.status = action.payload.status;
            state.created_at = action.payload.created_at;
        },

        setImage: (state, action) => {
            state.images.push(action.payload);
        },

        clearImages: (state) => {
            state.images = [];
        },

        removeImage: (state, action) => {
            state.images = state.images.filter(image => image !== action.payload);
        },

        resetBill: (state) => {
            state._id = '';
            state.name = '';
            state.description = '';
            state.company = '';
            state.amount = 0;
            state.expense_category = '';
            state.source_account = '';
            state.payment_method = '';
            state.tax_amount = 0;
            state.tax_rate = '';
            state.employee = '';
            state.supplier = '';
            state.created_at = '';
            state.images = [];
        },

        setBillId: (state, action) => {
            state._id = action.payload;
        },

        setBillName: (state, action) => {
            state.name = action.payload;
        },

        setBillDescription: (state, action) => {
            state.description = action.payload;
        },

        setBillCompany: (state, action) => {
            state.company = action.payload;
        },

        setBillAmount: (state, action) => {
            state.amount = action.payload;
        },

        setBillExpenseCategory: (state, action) => {
            state.expense_category = action.payload;
        },

        setBillSourceAccount: (state, action) => {
            state.source_account = action.payload;
        },

        setBillPaymentMethod: (state, action) => {
            state.payment_method = action.payload;
        },

        setBillTaxAmount: (state, action) => {
            state.tax_amount = action.payload;
        },

        setBillTaxRate: (state, action) => {
            state.tax_rate = action.payload;
        },

        setBillEmployee: (state, action) => {
            state.employee = action.payload;
        },

        setBillSupplier: (state, action) => {
            state.supplier = action.payload;
        },

        setBillStatus: (state, action) => {
            state.status = action.payload;
        },

        setBillCreatedby: (state, action) => {
            state.employee = action.payload;
        }
    },
});

export const billActions = billSlice.actions;