/**
 * NewListing.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { ScrollView, View, StyleSheet, Dimensions } from 'react-native';
import colors from '../../strings/colors';
import { ProgressSteps, ProgressStep } from 'react-native-progress-steps';
import Listing from '../../components/landing/Listing';              
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { useCreateCompanyCashupMutation, useUpdateCompanyCashupMutation } from '../../redux/api';
import Loader from '../../components/common/Loader';
import { uploadEvidenceImage } from '../../utils/app_utils';
import { CASHUPS, MY_LISTINGS } from '../../config/constants/nav-routes.const';
import CashupForm from './CashupForm';
import CashupEvidence from './CashUpEvidence';
import ViewCashup from './ViewCashup';

const screenWidth = Dimensions.get('window').width;

const Cashup = () => {

    const userState = useSelector(state => state.user);
    const companyState = useSelector(state => state.company);
    const cashup = useSelector(state => state.cashup);

    const [isCashupErrors, setIsCashupErrors] = useState(false);
    const [isImagesErrors, setIsImagesErrors] = useState(false);

    const [createCashup, createCashupResult] = useCreateCompanyCashupMutation();
    const [updateCashup, updateCashupResult] = useUpdateCompanyCashupMutation();

    const [uploadingImages, setUploadingImages] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Uploading Images...');

    const navigation = useNavigation();

    const { images } = useSelector(state => state.cashup);

    const progressStepsStyle = {
        activeStepIconBorderColor: colors.stay_blue, // blue color for the active step icon border
        activeLabelColor: colors.stay_blue,         // blue color for the active label
        activeStepNumColor: 'white',          // white color for the active step number
        activeStepIconColor: colors.stay_blue,       // blue color for the active step icon
        labelFontFamily: 'Poppins',
        marginBottom: 40,
        borderWidth: 4
    };

    const onNextStep = () => {
        setIsCashupErrors(false); // Reset error state before validation
    
        // Validate Cashier
        if (cashup.cashier==='') {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Cashier is required',
            });
            setIsCashupErrors(true);
            return;
        }
    
        // Validate Period Cash Sale
        if (cashup.period_cash_sale === 0) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Period cash sale amount is required',
            });
            setIsCashupErrors(true);
            return;
        }
    
        // Validate Location
        if (cashup.location==='') {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Location is required',
            });
            setIsCashupErrors(true);
            return;
        }
        
        // Proceed if all validations pass
        console.log('All validations passed. Proceed to the next step.');
    };

    const onImageSelectedStep = () => {
        setIsImagesErrors(false);
        if (images.length == 0) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'At least one image is required',
            });
            setIsImagesErrors(true);
            return;
        }
    }



    const onSubmission = async () => {


        if(cashup._id!==''){ 
            setLoadingMessage('Updating Listing...');
            updateCashup({
                ...cashup,
                created_by: userState._id,
            });

            return;
        }

        setLoadingMessage('Creating Cashup...');

        const filteredCashup = Object.fromEntries(
            Object.entries(cashup).filter(([_, value]) => value !== '')
        );
    
        try {
            const creatingCashup = await createCashup({
                ...filteredCashup,
                company: companyState._id,
                created_by: userState._id,
            }).unwrap();
    
            setUploadingImages(true);
    
            for (let i = 0; i < images.length; i++) {
                await uploadEvidenceImage(images[i], creatingCashup._id, i)
            }
    
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Cashup and evidence uploaded successfully',
            });

            navigation.navigate(CASHUPS);
    
        } catch (error) {
            console.log(error);
            
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: error.message || 'An error occurred',
            });
        } finally {

            setUploadingImages(false);
        }
    };

    useEffect(() => {
        if(updateCashupResult.isSuccess){
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'cashup updated successfully',
            });
            navigation.navigate(CASHUPS);
        }
        if(updateCashupResult.isError){
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: updateListingResult.error.data.message || 'An error occurred',
            });
        }
    }, [updateCashupResult.isLoading]);
    

    useEffect(() => {
        if (!userState._id) {
            // Redirect to login
            navigation.navigate(LOGIN);
        }
    }, [userState]);

    if (createCashupResult.isLoading || updateCashupResult.isLoading || uploadingImages) {
        return (
            <Loader message={loadingMessage} />
        );
    }

    return (
        <View style={styles.container}>
            <ProgressSteps {...progressStepsStyle} >
                <ProgressStep label="Cashup" errors={isCashupErrors} onNext={onNextStep} >
                    <CashupForm/>
                </ProgressStep>
                <ProgressStep label="Proof" errors={isImagesErrors} onNext={onImageSelectedStep}>
                    <ScrollView>
                        <CashupEvidence />
                    </ScrollView>
                </ProgressStep>
                <ProgressStep label="Finish" onSubmit={onSubmission} >
                    <ScrollView styles={{
                        flex: 1,
                        padding: 5,
                        backgroundColor: '#eee',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                       <ViewCashup preview={true} />
                    </ScrollView>
                </ProgressStep>
            </ProgressSteps>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 5,
        backgroundColor: colors.white,
    },
    layout: {
        flexDirection: 'column',
    },


    picker: {
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 25,
        marginBottom: 10,
        fontFamily: 'Poppins',

    },

    inputLabel: {
        fontFamily: 'Poppins',
        fontSize: screenWidth * 0.04,
        marginBottom: 5,
        marginLeft: 20,
        color: colors.light_gray2
    },

    imageContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    imageWrapper: {
        position: 'relative',
        margin: 5,
    },
    image: {
        width: 150,
        height: 150,
        borderRadius: 10,
    },
    removeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'red',
        padding: 5,
    },
    removeButtonText: {
        color: 'white',
        fontFamily: 'Poppins',
    },
});

export default Cashup;

