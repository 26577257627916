import React from 'react';
import { View, Text } from 'react-native';
import { Svg, Circle } from 'react-native-svg';
import colors from '../../../strings/colors';

const OxygenGaugeChart = ({ value, maxValue }) => {

    const getColor = (percentage) => {
        if (percentage < 30) return colors.red;  // Red for less than 30%
        else if (percentage < 50) return colors.gold;  // Yellow for less than 50%
        else if (percentage < 80) return colors.stay_blue;  // Blue for less than 80%
        else if (percentage < 99) return colors.green;  // Light green for less than 99%
        return colors.light_green;  // Green for 100% or more
      };

      const getStrokeColor = (percentage) => {
        return percentage>=100? colors.light_green: colors.light_gray2;
      }

  const size = 200;
  const strokeWidth = 20;
  const radius = (size / 2) - (strokeWidth / 2);
  const circumference = radius * Math.PI * 2;
  const percentage = (value / maxValue) * 100;
  const dashOffset = circumference * ((maxValue - value) / maxValue);
  const color = getColor(percentage);  // Determine the color based on the percentage
  const stroke_color = getStrokeColor(percentage);
  
  

  return (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Svg width={size} height={size}>
        <Circle
          stroke={stroke_color}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <Circle
          stroke={color}  // Use the dynamic color
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={dashOffset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </Svg>
      <Text style={{ 
        position: 'absolute', 
        fontSize: 18, 
        fontWeight: 'bold' }}>
        {percentage.toFixed(2)}%
      </Text>
    </View>
  );
};

export default OxygenGaugeChart;
