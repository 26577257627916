/**
 * ImagePreview.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */


// ImagePreview.jsx
import React from 'react';
import { View, Image, TouchableOpacity, Text, StyleSheet } from 'react-native';

const ImagePreview = ({ uri, onRemove }) => (
    <View style={styles.wrapper}>
        <Image source={{ uri }} style={styles.image} />
        <TouchableOpacity onPress={() => onRemove(uri)} style={styles.removeButton}>
            <Text style={styles.removeButtonText}>Remove</Text>
        </TouchableOpacity>
    </View>
);

const styles = StyleSheet.create({
    wrapper: { margin: 5, position: 'relative' },
    image: { width: 150, height: 150, borderRadius: 10 },
    removeButton: { position: 'absolute', top: 0, right: 0, backgroundColor: 'red', padding: 5 },
    removeButtonText: { color: 'white', fontWeight: 'bold' }
});

export default ImagePreview;
