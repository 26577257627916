/**
 * CameraInterface.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

// CameraInterface.jsx
import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { CameraView } from 'expo-camera';

const CameraInterface = ({ cameraRef, facing, toggleFacing, capturePhoto, closeCamera }) => (
    <CameraView style={styles.camera} ref={cameraRef} facing={facing}>
        <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={toggleFacing}>
                <Text style={styles.text}>Flip Camera</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.captureButton} onPress={capturePhoto}>
                <Text style={styles.text}>Capture</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelButton} onPress={closeCamera}>
                <Text style={styles.text}>Cancel</Text>
            </TouchableOpacity>
        </View>
    </CameraView>
);

const styles = StyleSheet.create({
    camera: { flex: 1, height: 400 },
    buttonContainer: { flexDirection: 'row', justifyContent: 'space-between', margin: 20 },
    button: { backgroundColor: 'blue', padding: 10, borderRadius: 10 },
    captureButton: { backgroundColor: 'green', padding: 10, borderRadius: 10 },
    cancelButton: { backgroundColor: 'grey', padding: 10, borderRadius: 10 },
    text: { color: 'white', fontWeight: 'bold', fontSize: 18 }
});

export default CameraInterface;
