/**
 * ViewCashup.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions, TouchableOpacity, Linking, Switch } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../strings/colors';
import Loader from '../../components/common/Loader';

import Toast from 'react-native-toast-message';
import ImageSlider from '../../components/common/ImageSlider';
import { cashupActions } from '../../redux/cashupSlices';
import { getCashupDifference, getCashupTotalCash, getSystemCashTotal, toCurrency } from '../../utils/app_utils';
import { useGetListingImagesQuery, useUpdateCompanyCashupMutation } from '../../redux/api';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import RoundedButton from '../../components/common/RoundedButton';
import moment from 'moment';
import { APPROVED, PENDING } from '../../config/constants/cashup-stutuses';
import { useNavigation } from '@react-navigation/native';
import { NEW_CASHUP } from '../../config/constants/nav-routes.const';
import { ADMIN, CASHIER } from '../../config/constants/retail-role-codes.const';

const screenWidth = Dimensions.get('window').width;

const ViewCashup = ({preview=false}) => {
    const dispatch = useDispatch();
    const cashup = useSelector(state => state.cashup);
    const user = useSelector(state => state.user);
    const employee = useSelector(state => state.employee);

    const navigation = useNavigation();

    const [updateCashup, updateCashupResult] = useUpdateCompanyCashupMutation();

    const { data: apiImages, isError: apiError, isLoading: apiLoading, isSuccess } = useGetListingImagesQuery(cashup._id!==''?cashup._id:null);

    useEffect(() => {
        if (isSuccess && !preview) {
            dispatch(cashupActions.clearImages());
            
            for (let i = 0; i < apiImages.length; i++) {
                dispatch(cashupActions.setImage(`${EXPO_PUBLIC_API_ENDPOINT}/stream/${apiImages[i].filename}`));
            }
        }
        if(apiError){
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'An error occurred while fetching the images',
            });
        }
    }, [apiLoading]);

    useEffect(() => {
        if (updateCashupResult.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Cashup Updated',
                text2: 'Cashup has been updated successfully',
            });
            navigation.goBack();
        }

        if (updateCashupResult.isError) {
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'An error occurred while updating the cashup',
            });
        }

    }, [updateCashupResult.isLoading]);

    if (apiLoading || updateCashupResult.isLoading) {
        return <Loader message="Just a moment..." />;
    }

    const fromNow = moment(cashup.created_at).fromNow();

    return (
        <ScrollView style={styles.container}>
            {apiLoading?<Loader message='Loading Images'/> : <ImageSlider images={cashup.images} />}
            <View style={styles.details}>

                <Text style={styles.title}>Cashup Details</Text>
                
                {!preview && <><View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Cashier</Text>
                    <Text style={styles.amountValue}>{cashup.cashier?.first_name || "No Name"}</Text>
                </View>
                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Handled by</Text>
                    <Text style={styles.amountValue}>{cashup.created_by.username}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Created</Text>
                    <Text style={styles.amountValue}>{`${fromNow} (${new Date(cashup.created_at).toLocaleDateString()})`}</Text>
                </View></>}

                <Text style={styles.sectionTitle}>Cash Details</Text>

                {renderDenominationRow("200 NAD notes", cashup.notes_200)}
                {renderDenominationRow("100 NAD notes", cashup.notes_100)}
                {renderDenominationRow("50 NAD notes", cashup.notes_50)}
                {renderDenominationRow("30 NAD notes", cashup.notes_30)}
                {renderDenominationRow("20 NAD notes", cashup.notes_20)}
                {renderDenominationRow("10 NAD notes", cashup.notes_10)}
                {renderDenominationRow("5 NAD coins", cashup.coins_5)}
                {renderDenominationRow("1 NAD coin", cashup.coins_1)}
                {renderDenominationRow("50 cents", cashup.coins_50c)}
                {renderDenominationRow("10 cents", cashup.coins_10c)}
                {renderDenominationRow("5 cents", cashup.coins_5c)}

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Total Cash In Drawer:</Text>
                    <Text style={styles.amountValue}>{toCurrency(cashup._id!==''?cashup.total_amont:getCashupTotalCash({...cashup}))}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Cash Out:</Text>
                    <Text style={styles.amountValue}>{toCurrency(cashup.cash_out)}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Cash Out Reason:</Text>
                    <Text style={styles.amountValue}>{cashup.cash_out_reason}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>POS Sale:</Text>
                    <Text style={styles.amountValue}>{toCurrency(cashup.period_cash_sale)}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Kazang Sale:</Text>
                    <Text style={styles.amountValue}>{toCurrency(cashup.kazang_sale)}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Total System Cash:</Text>
                    <Text style={styles.amountValue}>{toCurrency(cashup._id!==''?cashup.total_system_cash:getSystemCashTotal({...cashup}))}</Text>
                </View>

                <View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Short/Over</Text>
                    <Text style={styles.amountValue}>{toCurrency(cashup._id!==''?cashup.over_short_amount:getCashupDifference({...cashup}))}</Text>
                </View>
                {cashup.cashup_status===APPROVED &&<View style={styles.amountContainer}>
                    <Text style={styles.amountLabel}>Approved By</Text>
                    <Text style={styles.amountValue}>{cashup.approved_by.username}</Text>
                </View>}
            </View>
            {(cashup.cashup_status===PENDING && cashup._id!=='' && !preview && employee.role.code!==CASHIER) && <>
            {user._id===cashup.created_by._id && <RoundedButton title={'Edit Cashup'} onPress={() => {
                dispatch(cashupActions.setCashup({
                    cashier: cashup.cashier?._id || '', 
                    location: cashup.location?._id || '',
                    created_by: cashup.created_by?._id || '',
                    ...cashup
                }));
                navigation.navigate(NEW_CASHUP);
            }} style={styles.button} />}
            {(user._id!==cashup.created_by._id || employee.role.code===ADMIN) &&<RoundedButton backgroundColor={colors.light_green} title={'Approve'} onPress={() => {
                updateCashup({
                    _id: cashup._id,
                    cashup_status: APPROVED,
                    approved_by: user._id
                });
            }} style={styles.button} />}
            </>}
           
        </ScrollView>
    );
};

const renderDenominationRow = (label, amount) => (
    <View style={styles.amountContainer}>
        <Text style={styles.amountLabel}>{label}:</Text>
        <Text style={styles.amountValue}>{amount}</Text>
    </View>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
        marginBottom: 20,
    },
    details: {
        padding: 20,
        backgroundColor: '#eee',
        borderRadius: 10,
        marginVertical: 10,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        fontFamily: 'Poppins-Bold',
        marginBottom: 10,
    },
    description: {
        fontSize: 16,
        marginBottom: 20,
        fontFamily: 'Poppins',
    },
    amountContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    amountLabel: {
        fontSize: 16,
        color: colors.dark_gray,
        fontFamily: 'Poppins',
    },
    amountValue: {
        fontSize: 16,
        fontWeight: 'bold',
        fontFamily: 'Poppins-Bold',
        color: colors.green,
    },
    button: {
        marginVertical: 20,
    }
});

export default ViewCashup;
