import React, { useState } from 'react';
import { ScrollView, View, Image, Dimensions, StyleSheet, ActivityIndicator, TouchableOpacity } from 'react-native';
import colors from '../../strings/colors';

const screenWidth = Dimensions.get('window').width;

const ImageSlider = ({ images, onImagePress }) => {

    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [imageLoadings, setImageLoadings] = useState(() => images.map(() => true));

    const handleScroll = (event) => {
        const contentOffsetX = event.nativeEvent.contentOffset.x;
        const currentIndex = Math.round(contentOffsetX / screenWidth);
        setCurrentImageIndex(currentIndex);
    };

    const handleImageLoaded = (index) => {
        setImageLoadings(prev => prev.map((loading, i) => i === index ? false : loading));
    };

    const getImageSource = (image) => {
        // Check if the image string contains 'http' to determine if it's a remote URL
        return image.includes('http') ? { uri: image } : { uri: image.replace('file://', '') };
    };

    return (
        <>
            <ScrollView
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                onScroll={handleScroll}
                scrollEventThrottle={16}
                style={styles.imageSlider}
            >
                {images.map((image, index) => (
                    <TouchableOpacity key={index} onPress={() => onImagePress ? onImagePress(image) : null}>
                        <Image
                            source={getImageSource(image)}
                            style={styles.image}
                            onLoad={() => handleImageLoaded(index)}
                        />
                        {imageLoadings[index] && (
                            <ActivityIndicator style={styles.activityIndicator} size="large" color={colors.stay_blue} />
                        )}
                    </TouchableOpacity>
                ))}
            </ScrollView>
            <View style={styles.indicatorContainer}>
                {images.map((_, index) => (
                    <View
                        key={index}
                        style={[
                            styles.indicator,
                            currentImageIndex === index ? styles.indicatorActive : null,
                        ]}
                    />
                ))}
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    imageSlider: {
        height: 500, // Adjust according to your design requirements
    },
    image: {
        width: screenWidth,
        height: 500, // Adjust height accordingly
        resizeMode: 'contain',
    },
    activityIndicator: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
    indicatorContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 10,
    },
    indicator: {
        height: 10,
        width: 10,
        borderRadius: 5,
        backgroundColor: 'lightgray',
        marginHorizontal: 5,
    },
    indicatorActive: {
        backgroundColor: 'gray',
    },
});

export default ImageSlider;
