import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, Text, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
    useGetCompanyCashupsQuery,
    useGetCompanyEmployeesQuery,
    useGetStockLocationsQuery,
    useUpdateCompanyCashupMutation
} from '../../redux/api';
import Loader from '../../components/common/Loader';
import CashupItem from './CashupItem';
import RoundedButton from '../../components/common/RoundedButton';
import StayDropDown from '../../components/common/StayDropDown';
import { NEW_CASHUP, VIEW_CASHUP } from '../../config/constants/nav-routes.const';
import Toast from 'react-native-toast-message';

import { DATE_RANGE_FILTERS, TODAY, YESTERDAY } from '../../config/enums/date-range-filters.enum';
import { getDateRange } from '../../utils/app_utils';
import colors from '../../strings/colors';
import { cashupActions } from '../../redux/cashupSlices';
import { ADMIN, CASHIER, SUPERVISOR } from '../../config/constants/retail-role-codes.const';

const Cashups = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const company = useSelector(state => state.company);
    const employee = useSelector(state => state.employee);

    const employee_role_code = employee.role.code;
    
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedCashier, setSelectedCashier] = useState(employee_role_code === CASHIER ? employee._id : null);
    const [selectedDateRange, setSelectedDateRange] = useState(YESTERDAY);

    const [updateCashup, deleteCashupResults] = useUpdateCompanyCashupMutation();

    const { data: cashups, isLoading, refetch, isFetching } = useGetCompanyCashupsQuery({
        companyId: company._id,
        locationId: selectedLocation,
        cashierId: selectedCashier,
        ...getDateRange(selectedDateRange)
    });

    const { data: employees, isLoading: employeesLoading } = useGetCompanyEmployeesQuery(company._id);

    const { data: stockLocations, isLoading: stockLocationsLoading } = useGetStockLocationsQuery(company._id);

    const CREATE_AND_FILTER = [ADMIN, SUPERVISOR];

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    useEffect(() => {
        refetch();
    }, [selectedLocation, selectedCashier, selectedDateRange, refetch]);

    const dateRanges = DATE_RANGE_FILTERS;

    const handleCreateNew = () => {
        dispatch(cashupActions.clearCashup()); // Clear the cashup state
        dispatch(cashupActions.clearImages()); // Clear the cashup images state
        navigation.navigate(NEW_CASHUP); // Navigate to the Create Cashup screen
    };

    const handleView = (cashup) => {
        dispatch(cashupActions.setCashup({
            ...cashup
        }));
        dispatch(cashupActions.clearImages()); // Clear the cashup images state
        navigation.navigate(VIEW_CASHUP); // Navigate to the View Cashup screen
    };

    const handleEdit = (cashup) => {
        dispatch(cashupActions.setCashup({
            ...cashup,
            cashier: cashup.cashier?._id || '',
            location: cashup.location?._id || '',
        })); // Set the cashup state
        dispatch(cashupActions.clearImages()); // Clear the cashup images state
        navigation.navigate(NEW_CASHUP); // Navigate to the Edit Cashup screen
    };

    const handleDelete = (cashup) => {
        updateCashup(
            {
                _id: cashup._id,
                deleted: true
            }
        );
    }

    useEffect(() => {
        if (deleteCashupResults.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Cashup deleted successfully',
            });
            refetch();
        }

        if (deleteCashupResults.isError) {
            Toast.show({
                type: 'error',
                text1: 'Error Deleting cashup',
                text2: 'Cashup and evidence uploaded successfully',
            });
        }
    }, [deleteCashupResults.isLoading]);

    if (isLoading || deleteCashupResults.isLoading) return <Loader message='Loading Cashups...' />;

    return (
        <View style={styles.container}>
            {CREATE_AND_FILTER.includes(employee_role_code) && <>
                <RoundedButton title="Create New Cashup" onPress={handleCreateNew} />

                <StayDropDown
                    data={stockLocations ? stockLocations.map(location => ({ value: location.name, key: location._id })) : []}
                    placeholder="Filter By Stock Location"
                    onSelectedItem={setSelectedLocation}
                    defaultOption={selectedLocation}
                />

                <StayDropDown
                    data={employees ? employees.map(employee => ({ value: employee.first_name, key: employee._id })) : []}
                    placeholder="Filter By Cashier"
                    onSelectedItem={setSelectedCashier}
                    defaultOption={selectedCashier}
                />
            </>}

            <StayDropDown
                data={dateRanges.map(dateRange => ({ value: dateRange, key: dateRange }))}
                placeholder="Filter By Date Range"
                onSelectedItem={setSelectedDateRange}
                defaultOption={selectedDateRange}
            />

            <RoundedButton backgroundColor={colors.light_gray} title="Clear Filters" onPress={() => {
                setSelectedLocation(null);
                setSelectedCashier(employee_role_code === CASHIER ? employee._id : null);
                setSelectedDateRange(YESTERDAY);
            }} />

            {(isLoading || isFetching) ? <Loader message='Loading Cashups...' /> : (
                <FlatList
                    data={cashups || []}
                    refreshing={isFetching}
                    onRefresh={() => refetch()}
                    keyExtractor={item => item._id.toString()}
                    renderItem={({ item }) => (
                        <CashupItem
                            cashup={item}
                            onView={() => handleView(item)}
                            onEdit={() => handleEdit(item)}
                            onDelete={() => handleDelete(item)}
                        />
                    )}
                />
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10
    }
});

export default Cashups;
