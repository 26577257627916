// services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { EXPO_PUBLIC_API_ENDPOINT, EXPO_PUBLIC_APP_MODE } from '../config/app.conf';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: EXPO_PUBLIC_API_ENDPOINT,
    prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json');
        headers.set('Accept', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('Token', `Bearer` || null);
        headers.set('app-mode', EXPO_PUBLIC_APP_MODE)
        return headers;
    },
}),
  endpoints: (builder) => ({
    //auth endpoints
    login: builder.mutation({
        query: (body) => ({
            url: '/login',
            method: 'POST',
            body,
        }),
    }),
    register: builder.mutation({
        query: (body) => ({
            url: '/register',
            method: 'POST',
            body,
        }),
    }),
    //forgot password endpoints
    forgotPassword: builder.mutation({
        query: (body) => ({
            url: '/forgetpassword',
            method: 'POST',
            body,
        }),
    }),

    //changePassword

    changePassword: builder.mutation({
        query: (body) => ({
            url: '/changePassword',
            method: 'POST',
            body,
        }),
    }),

    //phone-search

    phoneSearch: builder.mutation({
        query: (body) => ({
            url: '/phone-search',
            method: 'POST',
            body,
        }),
    }),

    //complete-profile

    completeProfile: builder.mutation({
        query: (body) => ({
            url: '/complete-profile',
            method: 'POST',
            body,
        }),
    }),

    //updateProfile

    updateProfile: builder.mutation({
        query: (body) => ({
            url: `/users/${body._id}`,
            method: 'PUT',
            body,
        }),
    }),

    //createListing

    createListing: builder.mutation({
        query: (body) => ({
            url: '/property-listings',
            method: 'POST',
            body,
        }),
    }),

    //getListing

    getListing: builder.query({
        query: ({ page, pageSize }) => {
            const skip = (page - 1) * pageSize; // Calculate the skip value
    
            // Construct the URL with limit and skip for pagination, and other query parameters
            const queryParams = new URLSearchParams({
                populate: 'created_by',
                skip: skip, // Using skip for pagination
                limit: pageSize,
                sort:'-created_at',
                deleted: 'false'
            }).toString();
    
            return {
                url: `/property-listings?${queryParams}`,
                method: 'GET'
            };
        }
    }),
    

    //get My Listings

    getMyListings: builder.query({
        query: (id) => ({
            url: `/property-listings?created_by=${id}&&deleted=false`,
            method: 'GET',
        }),
    }),

    updateListing: builder.mutation({
        query: (body) => ({
            url: `/property-listings/${body._id}`,
            method: 'PUT',
            body,
        }),
    }),

    uploadListingImage: builder.mutation({
        query: (body) => ({
            url: `/upload/${body.listing}`,
            method: 'POST',
            body: body.formData
        }),
    }),

    //get property images

    getListingImages: builder.query({
        query: (id) => ({
            url: `/files/${id}`,
            method: 'GET',
        }),
    }),

    //delete property image

    deleteListingImage: builder.mutation({
        query: (filename) => ({
            url: `/delete-file/${filename}`,
            method: 'POST',
            body: {},
        }),
    }),

    //create listing interaction

    createListingInteraction: builder.mutation({
        query: (body) => ({
            url: '/property-interactions',
            method: 'POST',
            body,
        }),
    }),

    getEmployees: builder.query({
        query: (email) => ({
            url: `/employees?email=${email}&&populate=company&&populate=role`,
            method: 'GET',
        }),
    }),

    getAccountTypes: builder.query({
        query: (id) => ({
            url: `/account_types`,
            method: 'GET',
        }),
    }),
    //get comapny accounts by account type

    getCompanyAccountsByAccountType: builder.query({
        query: (body) => ({
            url: `/accounts?company=${body.company}&&account_type=${body.account_type}&&populate=account_type`,
            method: 'GET',
        }),
    }),

    getCompanyAccounts: builder.query({
        query: (id) => ({
            url: `/accounts?company=${id}&&populate=account_type`,
            method: 'GET',
        }),
    }),

    //create new account

    createAccount: builder.mutation({
        query: (body) => ({
            url: '/accounts',
            method: 'POST',
            body,
        }),
    }),

    //update account

    updateAccount: builder.mutation({
        query: (body) => ({
            url: `/accounts/${body._id}`,
            method: 'PUT',
            body,
        }),
    }),

    //delete account

    deleteAccount: builder.mutation({
        query: (id) => ({
            url: `/accounts/${id}`,
            method: 'DELETE',
        }),
    }),

    //CRUD for transactions

    //get all company transactions

    getCompanyTransactions: builder.query({
        query: (id) => ({
            url: `/transactions?company=${id}&&populate=account_credited_id&&populate=account_debited_id`,
            method: 'GET',
        }),
    }),

    //create new transaction

    createTransaction: builder.mutation({
        query: (body) => ({
            url: '/create-transaction',
            method: 'POST',
            body,
        }),
    }),

    //update transaction

    updateTransaction: builder.mutation({
        query: (body) => ({
            url: `/transactions/${body._id}`,
        }),
    }),

    //delete transaction

    deleteTransaction: builder.mutation({
        query: (id) => ({
            url: `/transactions/${id}`,
            method: 'DELETE',
        }),
    }),

    //update accounts credited and debited

    updateAccountsCreditedAndDebited: builder.mutation({
        query: (body) => ({
            url: `/update-accounts`,
            method: 'POST',
            body,
        }),
    }),

    //generate account statement

    generateAccountStatement: builder.mutation({
        query: (body) => ({
            url: `/account_statement`,
            method: 'POST',
            body,
        }),
    }),

    //Crud for company expenses

    //get all company expenses

    getCompanyExpenses: builder.query({
        query: ({ companyId, startDate, endDate, categoryId, accountId, employeeId, sortBy = '-created_at' }) => {
            // Initialize URLSearchParams to build the query string
            console.log(companyId)
            const queryParams = new URLSearchParams({
                company: companyId,
                deleted: 'false', // Example parameter, adjust as necessary
                sort: sortBy, // Sorting by created_at in descending order by default
            });
    
            // Optional filters based on provided values
            if (startDate && endDate) {
                queryParams.set('created_at__gte', startDate); // Greater than or equal to startDate
                queryParams.set('created_at__lte', endDate); // Less than or equal to endDate
            }

            if (categoryId) {
                queryParams.set('expense_category', categoryId); // Filter by expense category
            }

            if (accountId) {
                queryParams.set('source_account', accountId); // Filter by source account
            }

            if (employeeId) {
                queryParams.set('employee', employeeId); // Filter by employee
            }
    
            // Return the final request object
            return {
                url: `/expenses?${queryParams.toString()}&&populate=expense_category&&populate=source_account&&populate=employee&&deleted=false`,
                method: 'GET'
            };
        }
    }),
    

    //create new company expense

    createCompanyExpense: builder.mutation({
        query: (body) => ({
            url: '/expenses',
            method: 'POST',
            body,
        }),
    }),

    //update company expense

    updateCompanyExpense: builder.mutation({
        query: (body) => ({
            url: `/expenses/${body._id}`,
            method: 'PUT',
            body,
        }),
    }),

    //delete company expense

    deleteCompanyExpense: builder.mutation({
        query: (id) => ({
            url: `/expenses/${id}`,
            method: 'DELETE',
        }),
    }),

    //get expense accounts

    getExpenseAccounts: builder.query({
        query: (id) => ({
            url: `/expenses_accounts/${id}`,
            method: 'GET',
        }),
    }),

    //get assets accounts

    getAssetsAccounts: builder.query({
        query: (id) => ({
            url: `/assets_accounts/${id}`,
            method: 'GET',
        }),
    }),

    //get revenue accounts

    getRevenueAccounts: builder.query({
        query: (id) => ({
            url: `/income_accounts/${id}`,
        }),
    }),

    //get company expense suppliers

    getCompanyExpenseSuppliers: builder.query({
        query: (id) => ({
            url: `/suppliers?company=${id}`,
            method: 'GET',
        }),
    }),

    getCurrencies: builder.query({
        query: () => ({
            url: '/currencies',
            method: 'GET',
        }),
    }),
    //get business accounts by account type

    getBusinessAccountsByAccountType: builder.query({
        query: (account_type, company_id) => ({
            url: `/accounts?account_type=${account_type}&&company=${company_id}`,
            method: 'GET',
        }),
    }),

    //create company cashup

    createCompanyCashup: builder.mutation({
        query: (body) => ({
            url: '/cashups',
            method: 'POST',
            body,
        }),
    }),

    //get company cashups

    getCompanyCashups: builder.query({
        query: ({ companyId,startDate, endDate, locationId, cashierId, sortBy = '-created_at' }) => {
            
            const queryParams = new URLSearchParams({
                company: companyId, // Filter by company ID
                deleted: 'false',
                sort: sortBy, // Sorting by created_at in descending order by default
            });
    
            // Optional filters based on availability
            if (startDate && endDate) {
                queryParams.set('created_at__gte', startDate); // Greater than or equal to
                queryParams.set('created_at__lte', endDate); // Less than or equal to
            }
            if (locationId) {
                queryParams.set('location', locationId); // Filter by location
            }
            if (cashierId) {
                queryParams.set('cashier', cashierId); // Filter by cashier
            }
    
            return {
                url: `/cashups?${queryParams.toString()}&&populate=cashier&&populate=location&&populate=created_by&&populate=approved_by`, // Convert queryParams to string here
                method: 'GET'
            };
        }
    }),
    
    

    //update company cashup

    updateCompanyCashup: builder.mutation({
        query: (body) => ({
            url: `/cashups/${body._id}`,
            method: 'PUT',
            body,
        }),
    }),

    //create company bank deposit

    createCompanyDeposit: builder.mutation({
        query: (body) => ({
            url: '/bank_deposits',
            method: 'POST',
            body,
        }),
    }),

    //get company bank deposits

    getCompanyDeposits: builder.query({
        query: ({ companyId, startDate, endDate, sortBy = '-created_at' }) => {
            // Initialize URLSearchParams to build the query string
            const queryParams = new URLSearchParams({
                company: companyId,
                deleted: 'false', // Adjust this as necessary
                sort: sortBy, // Default sorting by `created_at` in descending order
            });
    
            // Optional filters based on provided values
            if (startDate && endDate) {
                queryParams.set('created_at__gte', startDate); // Greater than or equal to startDate
                queryParams.set('created_at__lte', endDate); // Less than or equal to endDate
            }
    
            // Construct and return the final request object
            return {
                url: `/bank_deposits?${queryParams.toString()}&&populate=bank_account&&populate=cash_account&&populate=created_by&&deleted=false`,
                method: 'GET'
            };
        }
    }),
    

    //update company bank deposit

    updateCompanyDeposit: builder.mutation({
        query: (body) => ({
            url: `/bank_deposits/${body._id}`,
            method: 'PUT',
            body,
        }),
    }),

    //get company bank accounts

    getCompanyBankAccounts: builder.query({
        query: (id) => ({
            url: `/bank_accounts?company=${id}`,
            method: 'GET',
        }),
    }),

    getCompanyEmployees: builder.query({
        query: (id) => ({
            url: `/employees?company=${id}&&populate=role`,
            method: 'GET',
        }),
    }),

    getStockLocations: builder.query({
        query: (id) => ({
            url: `/stock_locations?company=${id}`,
            method: 'GET',
        }),
    }),



  }),
});

export const { 
    //auth endpoints
    useLoginMutation,
    useRegisterMutation,
    useForgotPasswordMutation,
    useChangePasswordMutation,
    //phone-search
    usePhoneSearchMutation,
    //complete-profile
    useCompleteProfileMutation,
    //update-profile
    useUpdateProfileMutation,
    //create-listing
    useCreateListingMutation,
    //get-listing
    useGetListingQuery,
    useUpdateListingMutation,
    useGetMyListingsQuery,
    useUploadListingImageMutation,
    useGetListingImagesQuery,
    useDeleteListingImageMutation,
    useCreateListingInteractionMutation,
    useGetEmployeesQuery,
    useGetAccountTypesQuery,
    useGetCompanyAccountsByAccountTypeQuery,
    useGetCompanyAccountsQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    useDeleteAccountMutation,
    useGetCompanyTransactionsQuery,
    useCreateTransactionMutation,
    useUpdateTransactionMutation,
    useDeleteTransactionMutation,
    useUpdateAccountsCreditedAndDebitedMutation,
    useGenerateAccountStatementMutation,
    useGetCompanyExpensesQuery,
    useCreateCompanyExpenseMutation,
    useUpdateCompanyExpenseMutation,
    useDeleteCompanyExpenseMutation,
    useGetExpenseAccountsQuery,
    useGetAssetsAccountsQuery,
    useGetRevenueAccountsQuery,
    useGetCompanyExpenseSuppliersQuery,
    useGetCurrenciesQuery,
    useGetBusinessAccountsByAccountTypeQuery,
    useCreateCompanyCashupMutation,
    useGetCompanyCashupsQuery,
    useUpdateCompanyCashupMutation,
    useCreateCompanyDepositMutation,
    useGetCompanyDepositsQuery,
    useUpdateCompanyDepositMutation,
    useGetCompanyBankAccountsQuery,
    useGetCompanyEmployeesQuery,
    useGetStockLocationsQuery,
 } = api;
