/**
 * retail-role-codes.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const ADMIN = 'admin';
const SUPERVISOR = 'supervisor';
const CASHIER = 'cashier';

export {
    ADMIN,
    SUPERVISOR,
    CASHIER
}