import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Banner from '../../components/landing/Banner';
import FilterButtons from '../../components/landing/FilterButtons';
import Listings from '../../components/landing/Listings';
import PotenciaDashboard from '../../components/retail/potencia/PotenciaDashboard';
const Landing = () => {
 
  const handleFilterPress = (filter) => {
    setSelectedFilter(filter);
    // Implement filter logic here
  };

  return (
  
      <PotenciaDashboard />
  
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    
  },
});

export default Landing;
