/**
 * BillItem.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Modal, Button } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import colors from '../../strings/colors';
import { toCurrency } from '../../utils/app_utils';
import ConfirmModal from '../../components/common/ConfirmModal';
import { useSelector } from 'react-redux';
import { PENDING } from '../../config/constants/approval-statues.const';
import moment from 'moment';

const BillItem = ({ bill, onEdit, onView, onDelete }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const user = useSelector(state => state.user);

  const timeAgo = moment(bill.created_at).fromNow();

  const handleDeletePress = () => {
    setShowDeleteConfirm(true);
  };

  const confirmDelete = (confirm) => {
    if (confirm) {
        onDelete(bill);
    }
    setShowDeleteConfirm(false);
  };

  return (
    <View style={[styles.container,{
        borderLeftColor: bill.status===PENDING ? colors.red : colors.green,
        borderLeftWidth: 5,
    }]}>
      <View style={styles.info}>
        <Text style={styles.title}>{bill.name}</Text>
        <Text style={styles.amount}>{toCurrency(bill.amount)}</Text>
        <Text style={styles.category}>Paid from {bill.source_account?.name}</Text>
        <Text style={styles.created}>{timeAgo}</Text>
        <Text style={styles.created}>Paid By {bill.employee?.username}</Text>
       
      </View>
      <TouchableOpacity style={styles.actionButton} onPress={() => onView(bill)}>
        <MaterialIcons name="remove-red-eye" size={22} color={colors.stay_blue} />
      </TouchableOpacity>
      {bill.status===PENDING &&<><TouchableOpacity style={styles.actionButton} onPress={() => onEdit(bill)}>
        <MaterialIcons name="edit" size={22} color={colors.stay_blue} />
      </TouchableOpacity>
      <TouchableOpacity style={styles.deleteButton} onPress={handleDeletePress}>
        <MaterialIcons name="delete" size={22} color={colors.red} />
      </TouchableOpacity></>}

      {/* Confirmation Modal */}
      <ConfirmModal
        isVisible={showDeleteConfirm}
        onConfirm={() => confirmDelete(true)}
        message="Are you sure you want to delete this bill?"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    marginBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
  info: {
    flex: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Poppins-Bold',
    textTransform: 'uppercase',
  },
  subtitle: {
    color: 'grey',
    fontFamily: 'Poppins',
  },
  amount: {
    fontSize: 18,
    fontWeight: 'normal',
    fontFamily: 'Poppins-Bold',
    color: colors.green,
  },
  category: {
    fontSize: 11,
    fontWeight: 'normal',
    fontFamily: 'Poppins',
    color: colors.gray,
  },
  created: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    color: colors.light_gray,
  },
  actionButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    marginRight: 10,
    backgroundColor: '#eee',
  },
  deleteButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    width: 36,
    borderRadius: 50,
    backgroundColor: '#eee',
  },
});

export default BillItem;
