import React, { useState } from 'react';
import { View, Text, Image, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { FOR_RENT } from '../../config/enums/property-statuses.enum';
import moment from 'moment';
import { toCurrency } from '../../utils/app_utils';
import colors from '../../strings/colors';

const Listing = ({ data, onViewProp }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const timeAgo = moment(data.created_at).fromNow();

  const onViewPropHere = () => {
    onViewProp(data);
  };

  const handleImageLoaded = () => {
    setIsImageLoaded(true);
  };

  return (
    <TouchableOpacity style={styles.card} onPress={onViewPropHere}>
      <View style={styles.imageContainer}>
        {!isImageLoaded && (
          <ActivityIndicator size="large" color={colors.light_gray2} style={styles.activityIndicator} />
        )}
        <Image
          source={{ uri: data.image }}
          style={styles.image}
          onLoad={handleImageLoaded}
          resizeMode='cover'
          onError={() => setIsImageLoaded(true)}
        />
      </View>
      <View style={styles.infoContainer}>
        <Text style={styles.title}>{data.name}</Text>
        {data.propertyStatus === FOR_RENT ? (
          <Text style={styles.price}>{toCurrency(data.propertyRent)} - {`(${data.propertyStatus})`}</Text>
        ) : (
          <Text style={styles.price}>{toCurrency(data.purchaseAmount)} - {`(${data.propertyStatus})`}</Text>
        )}
        <Text style={styles.location}>{data.town}, {data.suburb}</Text>
        <Text style={styles.listedTime}>{`Listed ${timeAgo}`}</Text>
        <View style={styles.details}>
          <Detail icon="hotel" detail={`${data.numberOfRooms} BR`} />
          <Detail icon="bathtub" detail={`${data.numberOfBathrooms} Bath`} />
          <Detail icon="aspect-ratio" detail={`${data.size} ${data.sizeUnit}`} />
          <Detail icon="directions-car" detail={`${data.numberOfParking} Parking`} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const Detail = ({ icon, detail }) => {
  return (
    <View style={styles.detailItem}>
      <MaterialIcons name={icon} size={20} color={colors.gray} />
      <Text style={styles.detailText}>{detail}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    backgroundColor: '#fff',
    width: '100%',
    overflow: 'hidden',
    marginBottom: 20,
    maxWidth: 700,
    alignSelf: 'center',
  },
  infoContainer: {
    padding: 10,
  },
  title: {
    fontFamily:'Poppins-Bold',
    fontSize: 18,
    fontWeight: 'bold',
  },
  price: {
    fontFamily:'Poppins',
    fontSize: 18,
    color: colors.gray,
    marginVertical: 5,
  },
  location: {
    fontSize: 16,
    color: 'gray',
    fontFamily: 'Poppins',
  },
  listedTime: {
    fontSize: 13,
    color: colors.light_gray,
    fontFamily: 'Poppins',
  },
  details: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
  },
  detailItem: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailText: {
    marginLeft: 5,
    fontSize: 14,
    color: 'gray',
    fontFamily: 'Poppins',
  },
  imageContainer: {
    width: '100%',
    height: 300,
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

export default Listing;
