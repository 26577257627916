/**
 * approval-statues.const.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

const PENDING = 'pending';
const APPROVED = 'approved';

export {
    PENDING,
    APPROVED
}