/**
 * depositSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import { createSlice } from '@reduxjs/toolkit';

export const depositSlice = createSlice({
    name: 'deposit',
    initialState: {
        _id: '',
        bank_account: '',
        cash_account: '',
        company: '',
        status: '',
        deposit_date: '',
        deposit_amount: 0,
        created_by: '',
        approved_by: '',
        created_at: '',
        images: []
    },

    reducers: {
        setDeposit: (state, action) => {
            state._id = action.payload._id;
            state.bank_account = action.payload.bank_account;
            state.cash_account = action.payload.cash_account;
            state.company = action.payload.company;
            state.status = action.payload.status;
            state.deposit_date = action.payload.deposit_date;
            state.deposit_amount = action.payload.deposit_amount;
            state.created_by = action.payload.created_by;
            state.approved_by = action.payload.approved_by;
            state.created_at = action.payload.created_at;
        },

        resetDeposit: (state) => {
            state._id = '';
            state.bank_account = '';
            state.cash_account = '';
            state.company = '';
            state.status = '';
            state.deposit_date = '';
            state.deposit_amount = 0;
            state.created_by = '';
            state.approved_by = '';
            state.created_at = '';
            state.images = [];
        },

        setImage: (state, action) => {
            state.images.push(action.payload);
        },

        clearImages: (state) => {
            state.images = [];
        },

        removeImage: (state, action) => {
            state.images = state.images.filter(image => image !== action.payload);
        },


        setDepositStatus: (state, action) => {
            state.status = action.payload;
        },

        setDepositDate: (state, action) => {
            state.deposit_date = action.payload;
        },

        setDepositAmount: (state, action) => {
            state.deposit_amount = action.payload;
        },

        setDepositBankAccount: (state, action) => {
            state.bank_account = action.payload;
        },

        setDepositCashAccount: (state, action) => {
            state.cash_account = action.payload;
        },

        setDepositCompany: (state, action) => {
            state.company = action.payload;
        },

        setDepositCreatedBy: (state, action) => {
            state.created_by = action.payload;
        },

        setDepositApprovedBy: (state, action) => {
            state.approved_by = action.payload;
        },

        setDepositCreatedAt: (state, action) => {
            state.created_at = action.payload;
        },

        setDepositId: (state, action) => {
            state._id = action.payload;
        },
    }


});

export const depositActions = depositSlice.actions;