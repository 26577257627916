import React from 'react';
import { TouchableOpacity, Image, StyleSheet, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import colors from '../../strings/colors';
import ProfilePic from './ProfilePic';
import { LOGIN } from '../../config/constants/nav-routes.const';

const ProfileIcon = () => {
    const navigation = useNavigation();
    const user = useSelector(state => state.user);

    const profile_url = `${EXPO_PUBLIC_API_ENDPOINT}/stream/profile.jpg-${user._id}`;
    const onProfilePress = () => {
        navigation.navigate(user._id !== '' ? 'Profile' : 'Login');
    };

    // Check if user is logged in
    if (user._id !== '') {
        // User is logged in, show profile image
        return (
            <TouchableOpacity style={styles.profileIcon} onPress={onProfilePress}>
                <ProfilePic url={profile_url} width={45} username={user?.username ||'User'} />
            </TouchableOpacity>
        );
    } else {
        // User is not logged in, show login button
        navigation.navigate(LOGIN);
    }
};

const styles = StyleSheet.create({
    profileIcon: {
        width: 45,
        height: 45,
        borderRadius: 22.5,
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#007bff',
        borderWidth: 2,
        borderColor: '#FFFFFF',
        marginRight: 10,
    },
    profileButton: {
      width: 75,
      height: 45,
      fontFamily: 'Poppins-Bold', 
      borderRadius: 12,
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#eee',
      borderWidth: 2,
      borderColor: '#FFFFFF',
      marginRight: 10,
      
  },
    image: {
        width: '100%',
        height: '100%',
    },
    loginText: {
        color: colors.stay_blue,
        fontWeight: 'bold',
        fontSize: 16,
    }
});

export default ProfileIcon;
