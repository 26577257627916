import React, { useRef, useEffect } from 'react';
import { View, TouchableOpacity, Text, StyleSheet,Animated, Platform  } from 'react-native';
import colors from '../../strings/colors';
import { Picker } from '@react-native-picker/picker';
import { useSelector, useDispatch } from 'react-redux';
import {
    FEED_FILTERS,
    LISTED_PERIODS_FILTERS,
    PRICING_ORDERS_FILTERS,
    LISTING_STATUSES_FILTERS,
    LISTING_TYPES_FILTERS
} from '../../config/enums/filters.enum';
import { filterActions } from '../../redux/filterSlice';
import PriceRangePicker from './PriceRangePicker';
import ListingTypeCheckboxes from './ListingTypeDropdown';
import TownSelector from './TownSelector';
import { toCurrency } from '../../utils/app_utils';

const FilterButtons = () => {
    const filterState = useSelector(state => state.filter);
    const dispatch = useDispatch();

    const marginTopAnim = useRef(new Animated.Value(-40)).current; 

    const onFeedFilterPress = (filter) => {
        dispatch(filterActions.setFeedFilter(filter));
    };

    const onListingStatusPress = (status) => {
        dispatch(filterActions.setListingStatus(status));
    }

    useEffect(() => {
        Animated.spring(marginTopAnim, {
            toValue: filterState.filterVisible ? -200 : -40,
            useNativeDriver: false,
        }).start();
    }, [filterState.filterVisible, marginTopAnim]);

    if(!filterState.filterVisible) return null;

    return (
        
            <Animated.View style={[styles.mainContainer, { marginTop: marginTopAnim }]}>
            <View style={styles.filtersContainer}>
                {FEED_FILTERS.map((filter, index) => (
                    <TouchableOpacity
                        key={index}
                        style={[styles.filterButton,
                        { backgroundColor: filter === filterState.feedFilter ? colors.stay_blue : "#eee" }]}
                        onPress={() => onFeedFilterPress(filter)}
                    >
                        <Text style={{
                            color: filter === filterState.feedFilter ? colors.white : colors.black,
                            fontFamily: 'Poppins'
                        }}>
                            {filter}
                        </Text>
                    </TouchableOpacity>
                ))}
            </View>

            
                    <Text style={styles.filterLable}>LISTING TYPES</Text>
                    <ListingTypeCheckboxes />

                    <Text style={styles.filterLable}>TOWNS</Text>
                    <TownSelector />

                    <Text style={styles.filterLable}>LISTING STATUS</Text>
                    <View style={styles.filtersContainer}>
                        {LISTING_STATUSES_FILTERS.map((status, index) => (
                            <TouchableOpacity
                                key={`status-${index}`}
                                style={[styles.filterButton, {
                                    backgroundColor: status === filterState.listingStatus ? colors.stay_blue : "#eee"
                                }]}
                                onPress={() => onListingStatusPress(status)}
                            >
                                <Text style={[styles.filterText, {
                                    color: status === filterState.listingStatus ? colors.white : colors.black
                                }]}>{status}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>


                    {Platform.OS!=='web' && <><Text style={styles.filterLable}>{`PRICE RANGES FROM ${toCurrency(filterState.price_low)} TO ${ toCurrency(filterState.price_high)}`}</Text>
                    <View style={styles.filtersContainer}>
                        <PriceRangePicker />
                    </View></>}

                    <Text style={styles.filterLable}>PRICING ORDER</Text>
                    <View style={styles.filtersContainer}>
                        {PRICING_ORDERS_FILTERS.map((order, index) => (
                            <TouchableOpacity
                                key={`order-${index}`}
                                style={[styles.filterButton, {
                                    backgroundColor: order === filterState.pricingOrder ? colors.stay_blue : "#eee"
                                }]}
                                onPress={() => dispatch(filterActions.setPricingOrder(order))}
                            >
                                <Text style={[styles.filterText, {
                                    color: order === filterState.pricingOrder ? colors.white : colors.black
                                }]}>{order}</Text>
                            </TouchableOpacity>
                        ))}
                    </View>
        
        </Animated.View>
                            
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flexDirection: 'column',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '95%',
        backgroundColor: colors.white,
        borderRadius: 30,
        marginBottom: 10,
    },
    filtersContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderRadius: 30,
    },

    filterButton: {
        borderRadius: 20,
        padding: 10,
        paddingHorizontal: 20,
        margin: 1,
        backgroundColor: "#eee",
    },
    filterText: {
        color: colors.black,
        fontFamily: 'Poppins',
    },

    filterLable: {
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 5,
        fontWeight: 'bold',
        fontFamily: 'Poppins-Bold',
        color: colors.light_gray
    },
    // ... other styles ...
});

export default FilterButtons;
