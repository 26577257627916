/**
 * filterSlice.js
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */


const { createSlice } = require("@reduxjs/toolkit");
const { ALL, LOW_TO_HIGH, ALL_TIME } = require("../config/enums/filters.enum");

export const filterSlice = createSlice({
    name: "filter",
    initialState: {

        filterVisible: false,
        locations: [],
        price_low: 0,
        price_high: 0,
        listingStatus: ALL,
        lsitingType: ALL,
        feedFilter: ALL,
        pricingOrder: LOW_TO_HIGH,
        listedPeriod: ALL_TIME,
        cheapestListingPrice: 1000,
        highestListingPrice: 1000000,
    },
    reducers: {
        setFilterVisible: (state, action) => {
            state.filterVisible = action.payload;
        },
        setLocations: (state, action) => {
            state.locations = action.payload;
        },
        setPriceLow: (state, action) => {
            state.price_low = action.payload;
        },
        setPriceHigh: (state, action) => {
            state.price_high = action.payload;
        },
        setListingStatus: (state, action) => {
            state.listingStatus = action.payload;
        },
        setListingType: (state, action) => {
            state.lsitingType = action.payload;
        },
        setFeedFilter: (state, action) => {
            state.feedFilter = action.payload;
        },
        setPricingOrder: (state, action) => {
            state.pricingOrder = action.payload;
        },
        setListedPeriod: (state, action) => {
            state.listedPeriod = action.payload;
        },
        setCheapestListingPrice: (state, action) => {
            state.cheapestListingPrice = action.payload;
        },
        setHighestListingPrice: (state, action) => {
            state.highestListingPrice = action.payload;
        },

        clearFilter: (state) => {
            state.locations = [];
            state.price_low = 1000;
            state.price_high = 20000;
            state.listingStatus = ALL;
            state.lsitingType = ALL;
            state.feedFilter = ALL;
            state.pricingOrder = LOW_TO_HIGH;
            state.listedPeriod = ALL_TIME;
            state.cheapestListingPrice = 1000;
            state.highestListingPrice = 1000000;
        },
    },
});

export const filterActions = filterSlice.actions; 

