/**
 * Banner.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React from 'react';
import { View, Image, StyleSheet, Text } from 'react-native';

const Banner = () => {
  return (
    <View style={styles.bannerContainer}>
      <Image
        source={{ uri: 'https://photos.zillowstatic.com/fp/b1c1e15901c9265881bf173e06582b66-cc_ft_960.jpg' }} // Replace with your banner image URL
        style={styles.bannerImage}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    width: '100%',
    height: 200, // Adjust the height as needed
    marginBottom: 10,
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  }
});

export default Banner;
