import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { SelectList } from 'react-native-dropdown-select-list';
import colors from '../../strings/colors';
import { useSelector, useDispatch } from 'react-redux';
import { filterActions } from '../../redux/filterSlice';
import { LISTING_TYPES_FILTERS } from '../../config/enums/filters.enum';

const ListingTypeDropdown = () => {
    const filterState = useSelector(state => state.filter);
    const dispatch = useDispatch();

    const data = LISTING_TYPES_FILTERS.map(type => ({key: type, value: type}));

    return (
        
            <SelectList
                setSelected={selectedItem => dispatch(filterActions.setListingType(selectedItem))}
                data={data}
                placeholder="Select listing type"
                boxStyles={styles.dropdown}
                dropdownTextStyles={styles.dropdownText}
                defaultOption={filterState.listingType}
            />
       
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    filterLabel: {
        fontSize: 16,
        marginBottom: 10,
        color: colors.black,
        fontFamily: 'Poppins'
    },
    dropdown: {
        borderWidth: 1,
        borderColor: '#eee',
        backgroundColor: '#eee',
        width: 300,
        borderRadius: 20,
        paddingVertical: 8,
        paddingHorizontal: 10,
    },
    dropdownText: {
        color: colors.black,
        fontFamily: 'Poppins',
    },
    // Additional styles if needed
});

export default ListingTypeDropdown;
