/**
 * Deposits.jsx
 *
 * @autor Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useState, useEffect, useCallback } from 'react';
import { View, FlatList, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import Loader from '../../components/common/Loader';
import DepositItem from './DepositItem';
import StayDropDown from '../../components/common/StayDropDown';
import RoundedButton from '../../components/common/RoundedButton';
import { NEW_DEPOSIT, VIEW_DEPOSIT } from '../../config/constants/nav-routes.const';
import { depositActions } from '../../redux/depositSlice';
import { ADMIN, SUPERVISOR } from '../../config/constants/retail-role-codes.const';
import { DATE_RANGE_FILTERS, TODAY } from '../../config/enums/date-range-filters.enum';
import { getDateRange } from '../../utils/app_utils';
import Toast from 'react-native-toast-message';
import { useGetCompanyDepositsQuery, useUpdateCompanyDepositMutation } from '../../redux/api';

const Deposits = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const company = useSelector(state => state.company);
    const employee = useSelector(state => state.employee);
    const employeeRoleCode = employee.role.code;

    const [selectedDateRange, setSelectedDateRange] = useState(TODAY);

    const { data: deposits, isLoading, refetch, isFetching } = useGetCompanyDepositsQuery({
        companyId: company._id,
        ...getDateRange(selectedDateRange),
    });
    const [updateDeposit, deleteDepositResults] = useUpdateCompanyDepositMutation();

    const CREATE_AND_FILTER = [ADMIN, SUPERVISOR];

    useFocusEffect(
        useCallback(() => {
            refetch();
        }, [refetch])
    );

    useEffect(() => {
        refetch();
    }, [selectedDateRange, refetch]);

    const dateRanges = DATE_RANGE_FILTERS;

    const handleCreateNew = () => {
        dispatch(depositActions.resetDeposit());
        navigation.navigate(NEW_DEPOSIT);
    };

    const handleView = (deposit) => {
        dispatch(depositActions.setDeposit(deposit));
        navigation.navigate(VIEW_DEPOSIT);
    };

    const handleEdit = (deposit) => {
        dispatch(depositActions.setDeposit(deposit));
        navigation.navigate(NEW_DEPOSIT);
    };

    const handleDelete = (deposit) => {
        updateDeposit({
            _id: deposit._id,
            deleted: true
        });
    };

    const handleClearFilters = () => {
        setSelectedDateRange(TODAY);
    };

    useEffect(() => {
        if (deleteDepositResults.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Deposit deleted successfully',
            });
            refetch();
        }
    }, [deleteDepositResults.isLoading]);

    if (isLoading || isFetching || deleteDepositResults.isLoading) {
        return <Loader message="Loading Deposits..." />;
    }

    return (
        <View style={styles.container}>
            {CREATE_AND_FILTER.includes(employeeRoleCode) && (
                <RoundedButton title="Create New Deposit" onPress={handleCreateNew} />
            )}

            <StayDropDown
                data={dateRanges.map(range => ({ value: range, key: range }))}
                placeholder="Filter By Date Range"
                onSelectedItem={setSelectedDateRange}
                defaultOption={selectedDateRange}
            />

            <RoundedButton backgroundColor="lightgray" title="Clear Filters" onPress={handleClearFilters} />

            <FlatList
                data={deposits || []}
                refreshing={isFetching}
                onRefresh={refetch}
                keyExtractor={item => item._id.toString()}
                renderItem={({ item }) => (
                    <DepositItem
                        deposit={item}
                        onView={() => handleView(item)}
                        onEdit={() => handleEdit(item)}
                        onDelete={() => handleDelete(item)}
                    />
                )}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
});

export default Deposits;
