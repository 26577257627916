/**
 * ViewBill.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

import React, { useEffect } from 'react';
import { ScrollView, View, Text, StyleSheet, Dimensions } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import colors from '../../strings/colors';
import Loader from '../../components/common/Loader';
import { billActions } from '../../redux/billSlice';
import { useGetListingImagesQuery, useUpdateCompanyExpenseMutation } from '../../redux/api';
import { EXPO_PUBLIC_API_ENDPOINT } from '../../config/app.conf';
import moment from 'moment';
import { toCurrency } from '../../utils/app_utils';
import RoundedButton from '../../components/common/RoundedButton';
import ImageSlider from '../../components/common/ImageSlider';
import { NEW_BILL } from '../../config/constants/nav-routes.const';
import { useNavigation } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { PENDING } from '../../config/constants/approval-statues.const';


const screenWidth = Dimensions.get('window').width;

const ViewBill = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const bill = useSelector(state => state.bill);
    const user   = useSelector(state => state.user);
    const { data: apiImages, isLoading: apiLoading, isSuccess } = useGetListingImagesQuery(bill._id);

    const [updateBill, updateBillResult] = useUpdateCompanyExpenseMutation();

    useEffect(() => {
        if (isSuccess) {
            dispatch(billActions.clearImages());
            apiImages.forEach(image => {
                dispatch(billActions.setImage(`${EXPO_PUBLIC_API_ENDPOINT}/stream/${image.filename}`));
            });
        }
    }, [apiLoading]);

    useEffect(() => {
        if (updateBillResult.isSuccess) {
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Bill Approve successfully',
            });
            dispatch(billActions.resetBill());
            navigation.goBack();
        }
    }, [updateBillResult.isLoading]);

    const fromNow = moment(bill.created_at).fromNow();

    if (apiLoading || updateBillResult.isLoading) {
        return <Loader message="Loading Bill Details..." />;
    }

    return (
        <ScrollView style={styles.container}>
            <ImageSlider images={bill.images}/>
            <View style={styles.details}>
                <Text style={styles.title}>Bill Details</Text>
                <Text style={styles.sectionTitle}>Information</Text>
                <Text style={styles.info}>Name: {bill.name}</Text>
                <Text style={styles.info}>Amount: {toCurrency(bill.amount)}</Text>
                <Text style={styles.info}>Created: {`${fromNow} (${new Date(bill.created_at).toLocaleDateString()})`}</Text>
                <Text style={styles.info}>Description: {bill.description}</Text>
                {bill.status===PENDING &&<><RoundedButton title="Edit Bill" onPress={() => {
                    navigation.navigate(NEW_BILL);
                }} style={styles.button} />
                <RoundedButton title="Approve" backgroundColor={colors.light_green} onPress={() => {
                    updateBill({ _id: bill._id, status: 'approved', approvedBy: user._id });
                }} style={styles.button} /></>}
            </View>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        backgroundColor: colors.white,
    },
    details: {
        padding: 20,
        backgroundColor: '#eee',
        borderRadius: 10,
        marginVertical: 10,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 18,
        color: colors.dark_gray,
        marginVertical: 5,
    },
    info: {
        fontSize: 16,
        marginBottom: 5,
    },
    button: {
        marginTop: 20,
        alignSelf: 'center',
    }
});

export default ViewBill;
