/**
 * CameraPermissionPrompt.jsx
 *
 * @author Immanuel N Fransiskus <immanuel@siskus.com>
 * @copyright 2024
 * All rights reserved
 */

// CameraPermissionPrompt.jsx
import React from 'react';
import { View, Text, Button, StyleSheet } from 'react-native';

const CameraPermissionPrompt = ({ requestPermission }) => (
    <View style={styles.container}>
        <Text>We need your permission to show the camera</Text>
        <Button onPress={requestPermission} title="Grant Permission" />
    </View>
);

const styles = StyleSheet.create({
    container: { flex: 1, justifyContent: 'center' }
});

export default CameraPermissionPrompt;
